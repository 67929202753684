import ApiServiceBase from "@/api/ApiServiceBase";
import { apiBaseUrl } from "@/api/api.config";
import DynamicReportCategoryModel from "@/models/DynamicReportCategoryModel";

export default class DynamicReportCategoriesApiService extends ApiServiceBase {
  private static _instance: DynamicReportCategoriesApiService | undefined;

  public static get instance(): DynamicReportCategoriesApiService {
    if (!this._instance)
      this._instance = new DynamicReportCategoriesApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/dynamicReportCategory",
      withCredentials: true,
    });
  }

  public async getDynamicCategories(): Promise<
    DynamicReportCategoryModel[] | undefined
  > {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getAll",
      });
      return <DynamicReportCategoryModel[]>response.data;
    } catch (e) {
      return undefined;
    }
  }
}

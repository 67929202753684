import ApiServiceBase from "@/api/ApiServiceBase";
import { apiBaseUrl } from "@/api/api.config";
import ReportCategoryModel from "@/models/ReportCategoryModel";

export default class ReportCategoriesApiService extends ApiServiceBase {
  private static _instance: ReportCategoriesApiService | undefined;

  public static get instance(): ReportCategoriesApiService {
    if (!this._instance) this._instance = new ReportCategoriesApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/reportCategories",
      withCredentials: true,
    });
  }

  public async getCategories(): Promise<ReportCategoryModel[] | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getAll",
      });
      if (response.status !== 200) return undefined;

      return <ReportCategoryModel[]>response.data;
    } catch (e) {
      return undefined;
    }
  }
}

import { homePageModule } from "@/store/modules/homePage";
import { authorizationModule } from "@/store/modules/authorization";
import { reportCategoriesModule } from "@/store/modules/reportCategories";
import { reportsModule } from "@/store/modules/reports";
import { usersModule } from "@/store/modules/users";
import { dynamicReportCategoriesModule } from "@/store/modules/dynamicReportCategories";
import { dynamicReportModule } from "@/store/modules/dynamicReport";

import { Module } from "vuex-smart-module";

export const root = new Module({
  modules: {
    homePageModule,
    authorizationModule,
    reportCategoriesModule,
    reportsModule,
    usersModule,
    dynamicReportCategoriesModule,
    dynamicReportModule,
  },
});


import { defineComponent, PropType } from "vue";
import locale from "date-fns/locale/ru";
import datepicker from "vue3-datepicker";

export default defineComponent({
  name: "DatePeriodPicker",
  data() {
    return {
      fromValue: new Date(),
      toValue: new Date(),
      locale: locale,
    };
  },
  props: {
    from: {
      type: Object as PropType<Date>,
      default: new Date(Date.now()),
    },
    to: {
      type: Object as PropType<Date>,
      default: new Date(Date.now()),
    },
  },
  emits: ["update:from", "update:to"],
  watch: {
    fromValue(newValue) {
      this.$emit("update:from", newValue);
    },
    toValue(newValue) {
      this.$emit("update:to", newValue);
    },
    from(newValue) {
      this.fromValue = newValue;
    },
    to(newValue) {
      this.toValue = newValue;
    },
  },
  mounted() {
    this.fromValue = this.from;
    this.toValue = this.to;
  },
  components: {
    datepicker,
  },
});

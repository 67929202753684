import { Getters } from "vuex-smart-module";
import AuthorizationState from "@/store/modules/authorization/authorizationState";
import UserModel from "@/models/UserModel";

export default class AuthorizationGetters extends Getters<AuthorizationState> {
  get currentUser(): UserModel | undefined {
    return this.state.currentUser;
  }

  get isLoggedIn(): boolean {
    return this.state.isLoggedIn;
  }

  get loaded(): boolean {
    return this.state.loaded;
  }
}

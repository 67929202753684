import { Mutations } from "vuex-smart-module";
import ReportCategoriesState from "@/store/modules/reportCategories/reportCategoriesState";
import ReportCategoryModel from "@/models/ReportCategoryModel";

export default class ReportCategoriesMutations extends Mutations<ReportCategoriesState> {
  setReportCategories(payload: ReportCategoryModel[]): void {
    this.state.categories = payload;
  }

  setLoaded(payload: boolean): void {
    this.state.loaded = payload;
  }
}

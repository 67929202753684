<template>
  <a
    v-show="false"
    :key="downloadReportId"
    :href="downloadLink"
    ref="downloadButton"
    download
  />
  <ConfirmationDialog
    :show="deleteConfirmationDialog"
    question="Вы уверены, что хотите удалить отчёт?"
    @confirm="handleDeleteConfirmation"
    @decline="deleteConfirmationDialog = false"
  />
  <ConfirmationDialog
    :show="checkConfirmationDialog"
    question="Вы уверены, что хотите подтвердить отчёт?"
    @confirm="handleCheckConfirmation"
    @decline="checkConfirmationDialog = false"
  />
  <DataTable
    :value="reports"
    :rowGroupMode="groupByDate ? 'subheader' : undefined"
    :expandableRowGroups="groupByDate"
    :groupRowsBy="groupByDate ? 'period' : undefined"
    v-model:expandedRowGroups="expandedRowGroups"
  >
    <Column field="name" header="Название" />
    <Column header="Категория">
      <template #body="slotProps">
        {{ slotProps.data.category.name }}
      </template>
    </Column>
    <Column header="Дата создания">
      <template #body="slotProps">
        {{ createDateString(slotProps.data.date) }}
      </template>
    </Column>
    <Column header="Статус">
      <template #body="slotProps">
        <div class="status-container">
          <ReportStatus :checked="slotProps.data.checked" />
          <div class="status-buttons-container" v-if="!slotProps.data.checked">
            <Button
              icon="pi pi-check"
              class="p-button-rounded p-button-text p-button-success"
              @click="handleCheckButtonClick(slotProps.data.id)"
            />
          </div>
        </div>
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <div class="reports-table-button-container">
          <Button
            class="p-button-outlined"
            label="Перейти"
            @click="handleReportButtonClick(slotProps.data.id)"
            v-if="showGotoButton"
          />
          <Button
            class="p-button-success p-button-outlined"
            label="Загрузить"
            @click="handleDownloadButtonClick(slotProps.data.id)"
          />
          <Button
            icon="pi pi-trash"
            @click="handleDeleteButtonClick(slotProps.data.id)"
          />
        </div>
      </template>
    </Column>
    <template #groupheader="slotProps">
      <span>{{ slotProps.data.period }}</span>
    </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";

import ReportModel from "@/models/ReportModel";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import ReportStatus from "@/components/PersonalCabinet/ReportStatus.vue";

import moment from "moment";
import { apiBaseUrl } from "@/api/api.config";
import ConfirmationDialog from "@/components/PersonalCabinet/ConfirmationDialog.vue";
import useShowErrorToast from "@/useShowErrorToast";

export default defineComponent({
  name: "ReportsTable",
  data() {
    return {
      deleteConfirmationDialog: false,
      checkConfirmationDialog: false,
      confirmationDialogReportId: 0,
      downloadReportId: 0,
      expandedRowGroups: null,
    };
  },
  props: {
    reports: {
      type: Object as PropType<ReportModel[]>,
      required: true,
    },
    showGotoButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    groupByDate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    downloadLink(): string {
      return (
        apiBaseUrl + "/reports/download?id=" + this.downloadReportId.toString()
      );
    },
  },
  methods: {
    ...reportsModuleMapper.mapActions(["checkReport", "deleteReport"]),
    createDateString(date: Date): string {
      return moment(date).format("DD.MM.YYYY");
    },
    handleReportButtonClick(id: number) {
      this.$router.push({ path: "/cabinet/report", query: { reportId: id } });
    },
    showDeleteDialog(reportId: number) {
      this.deleteConfirmationDialog = true;
      this.confirmationDialogReportId = reportId;
    },
    showCheckDialog(reportId: number) {
      this.checkConfirmationDialog = true;
      this.confirmationDialogReportId = reportId;
    },
    handleCheckButtonClick(reportId: number) {
      this.showCheckDialog(reportId);
    },
    handleDeleteButtonClick(reportId: number) {
      this.showDeleteDialog(reportId);
    },
    async handleDeleteConfirmation() {
      this.deleteConfirmationDialog = false;
      const result = await this.deleteReport(this.confirmationDialogReportId);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось удалить отчёт",
          "Произошла ошибка сервера. Не удалось удалить отчёт. Обратитесь к системному администратору"
        );
    },
    async handleCheckConfirmation() {
      this.checkConfirmationDialog = false;
      const result = await this.checkReport(this.confirmationDialogReportId);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось проверить отчёт",
          "Произошла ошибка сервера. Не удалось проверить отчёт. Обратитесь к системному администратору"
        );
    },
    async handleDownloadButtonClick(reportId: number) {
      this.downloadFile(reportId);
    },
    updateDownloadButton() {
      (<HTMLAnchorElement>this.$refs.downloadButton).href = this.downloadLink;
    },
    downloadFile(reportId: number) {
      this.downloadReportId = reportId;
      this.updateDownloadButton();
      (<HTMLAnchorElement>this.$refs.downloadButton).click();
    },
  },
  setup() {
    const showErrorToast = useShowErrorToast();
    return { showErrorToast };
  },
  components: {
    ConfirmationDialog,
    DataTable,
    Column,
    Button,
    ReportStatus,
  },
});
</script>

<style lang="scss">
.reports-table-button-container {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}

.status-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .status-buttons-container {
    padding-left: 10px;
  }
}

.confirmation-content {
  display: flex;
  align-items: center;

  i {
    margin-left: 10px;
    margin-right: 20px;
  }
}
</style>

import AuthorizationState from "@/store/modules/authorization/authorizationState";
import AuthorizationGetters from "@/store/modules/authorization/authorizationGetters";
import AuthorizationMutations from "@/store/modules/authorization/authorizationMutations";
import AuthorizationActions from "@/store/modules/authorization/authorizationActions";

import { createMapper, Module } from "vuex-smart-module";

export const authorizationModule = new Module({
  state: AuthorizationState,
  getters: AuthorizationGetters,
  mutations: AuthorizationMutations,
  actions: AuthorizationActions,
});

export const authorizationModuleMapper = createMapper(authorizationModule);

import { Actions } from "vuex-smart-module";
import DynamicReportState from "@/store/modules/dynamicReport/dynamicReportState";
import DynamicReportGetters from "@/store/modules/dynamicReport/dynamicReportGetters";
import DynamicReportMutations from "@/store/modules/dynamicReport/dynamicReportMutations";
import DynamicReportsApiService, {
  DynamicReportCreationArguments,
} from "@/api/DynamicReportsApiService";

export default class DynamicReportActions extends Actions<
  DynamicReportState,
  DynamicReportGetters,
  DynamicReportMutations,
  DynamicReportActions
> {
  private static get _apiService(): DynamicReportsApiService {
    return DynamicReportsApiService.instance;
  }

  async findOrCreateDynamicReport(
    args: DynamicReportCreationArguments
  ): Promise<void> {
    try {
      const dynamicReport = await DynamicReportActions._apiService.findOrCreateDynamicReport(
        args
      );
      this.commit("setDynamicReport", dynamicReport);
    } catch (e) {
      this.commit("resetDynamicReport");
      return;
    }
  }

  async createNewDynamicReport(
    args: DynamicReportCreationArguments
  ): Promise<void> {
    try {
      const dynamicReport = await DynamicReportActions._apiService.createDynamicReport(
        args
      );
      this.commit("setDynamicReport", dynamicReport);
    } catch (e) {
      this.commit("resetDynamicReport");
      return;
    }
  }

  async fetchDynamicReportData(): Promise<void> {
    try {
      const report = this.getters.dynamicReport;
      if (!report) return;

      const reportData = await DynamicReportActions._apiService.getDynamicReportParsedData(
        report
      );

      this.commit("setDynamicReportData", reportData);
    } catch (e) {
      this.commit("resetDynamicReportData");
      return;
    }
  }

  async resetDynamicReport(): Promise<void> {
    this.commit("resetDynamicReport");
    this.commit("resetDynamicReportData");
  }
}

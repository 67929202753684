import { apiBaseUrl } from "@/api/api.config";
import UserModel from "@/models/UserModel";
import ApiServiceBase from "@/api/ApiServiceBase";

export default class AuthorizationApiService extends ApiServiceBase {
  private static _instance: AuthorizationApiService | undefined;

  public static get instance(): AuthorizationApiService {
    if (!this._instance) this._instance = new AuthorizationApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/authorization",
      withCredentials: true,
    });
  }

  public async logIn(
    login: string,
    password: string
  ): Promise<UserModel | undefined> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/login",
        data: {
          login: login,
          password: password,
        },
      });
      if (response.status !== 200) return undefined;

      return <UserModel>response.data.user;
    } catch (e) {
      return undefined;
    }
  }

  public async logOut(): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/logout",
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/isAuthorized",
      });

      if (response.status !== 200) return false;

      return response.data.isAuthorized;
    } catch (e) {
      return false;
    }
  }

  public async loggedInUser(): Promise<UserModel | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getAuthorizedUser",
      });
      if (response.status !== 200) return undefined;

      return UserModel.from(response.data);
    } catch (e) {
      return undefined;
    }
  }
}

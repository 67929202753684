import { Getters } from "vuex-smart-module";
import ReportCategoriesState from "@/store/modules/reportCategories/reportCategoriesState";
import ReportCategoryModel from "@/models/ReportCategoryModel";

export default class ReportCategoriesGetters extends Getters<ReportCategoriesState> {
  get categories(): ReportCategoryModel[] | undefined {
    return this.state.categories;
  }

  get loaded(): boolean {
    return this.state.loaded;
  }
}

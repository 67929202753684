import HomePageState from "@/store/modules/homePage/homePageState";
import HomePageMutations from "@/store/modules/homePage/homePageMutations";
import HomePageGetters from "@/store/modules/homePage/homePageGetters";
import HomePageActions from "@/store/modules/homePage/homePageActions";

import { createMapper, Module } from "vuex-smart-module";

export const homePageModule = new Module({
  state: HomePageState,
  getters: HomePageGetters,
  mutations: HomePageMutations,
  actions: HomePageActions,
});

export const homePageModuleMapper = createMapper(homePageModule);

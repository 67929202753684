<template>
  <div class="contact">
    <i class="pi" :class="[iconClass]" :style="iconStyle"></i>
    <div class="contact-value" :style="valueStyle">
      {{ value }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contact",
  props: {
    value: {
      type: String,
      required: true,
    },
    contactType: {
      type: String,
      required: true,
    },
    iconStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    valueStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    iconClass(): string {
      if (this.contactType == "phone") return "pi-phone";
      else if (this.contactType == "email") return "pi-envelope";
      return "";
    },
  },
});
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  align-items: center;
  .contact-value {
    margin-left: 5px;
  }
}
</style>


import ReportsTable from "@/components/PersonalCabinet/ReportsTable.vue";
import ProgressSpinner from "primevue/progressspinner";

import { defineComponent, PropType } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";

export default defineComponent({
  name: "Reports",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...reportsModuleMapper.mapGetters(["reports"]),
  },
  methods: {
    ...reportsModuleMapper.mapActions([
      "resetReports",
      "fetchReportsByCategory",
      "fetchAllReports",
    ]),
    async updateTable() {
      this.isLoading = true;
      this.resetReports();
      if (this.categoryId) await this.fetchReportsByCategory(this.categoryId);
      else await this.fetchAllReports();
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.updateTable();
  },
  props: {
    categoryId: {
      type: Object as PropType<number | undefined>,
      required: false,
    },
    updateId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  watch: {
    async categoryId() {
      await this.updateTable();
    },
    async updateId() {
      await this.updateTable();
    },
  },
  components: {
    ReportsTable,
    ProgressSpinner,
  },
});

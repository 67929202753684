
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contact",
  props: {
    value: {
      type: String,
      required: true,
    },
    contactType: {
      type: String,
      required: true,
    },
    iconStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    valueStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    iconClass(): string {
      if (this.contactType == "phone") return "pi-phone";
      else if (this.contactType == "email") return "pi-envelope";
      return "";
    },
  },
});


import { defineComponent } from "vue";
import XlsxModelView from "@/components/XlsxModelView/XlsxModelView.vue";
import DatePeriodPicker from "@/components/PersonalCabinet/DatePeriodPicker.vue";

import { dynamicReportModuleMapper } from "@/store/modules/dynamicReport";
import { dynamicReportCategoriesModuleMapper } from "@/store/modules/dynamicReportCategories";

import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { apiBaseUrl } from "@/api/api.config";
import useShowErrorToast from "@/useShowErrorToast";
import DynamicReportCategoryModel from "@/models/DynamicReportCategoryModel";
import { NomenclatureModel } from "@/models/NomenclatureModel";
import DynamicReportsApiService, {
  DynamicReportCreationArguments
} from "@/api/DynamicReportsApiService";

export default defineComponent({
  name: "DynamicReport",
  data() {
    return {
      isLoading: false,
      downloadReportId: 0,
      dateFrom: new Date(Date.now()),
      dateTo: new Date(Date.now()),
      nomenclaturesList: [] as NomenclatureModel[],
      selectedNomenclature: undefined as NomenclatureModel | undefined,
      nomenclatureFilter: ""
    };
  },
  components: {
    XlsxModelView,
    Button,
    ProgressSpinner,
    DatePeriodPicker,
    Dropdown,
    InputText
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  computed: {
    downloadLink(): string {
      return (
        apiBaseUrl +
        "/dynamicReports/downloadOriginal?id=" +
        this.downloadReportId.toString()
      );
    },
    ...dynamicReportModuleMapper.mapGetters([
      "dynamicReportData",
      "dynamicReport"
    ]),
    ...dynamicReportCategoriesModuleMapper.mapGetters(["dynamicCategories"]),
    currentCategory(): DynamicReportCategoryModel | undefined {
      const categories: undefined | DynamicReportCategoryModel[] = this
        .dynamicCategories;

      if (categories === undefined) return undefined;

      const filteringResult = categories.find((value) => {
        return value.id === this.categoryId;
      });

      if (filteringResult === undefined) return undefined;

      return filteringResult;
    },
    filteredNomenclatureList(): NomenclatureModel[] {
      return this.nomenclaturesList.filter((item) => {
        return item.name.includes(this.nomenclatureFilter);
      });
    }
  },
  methods: {
    ...dynamicReportModuleMapper.mapActions([
      "resetDynamicReport",
      "createNewDynamicReport",
      "findOrCreateDynamicReport",
      "fetchDynamicReportData"
    ]),
    ...dynamicReportCategoriesModuleMapper.mapActions([
      "fetchDynamicCategories"
    ]),
    showErrorToast() {
      this.showErrorToastDialog(
        "Произошла ошибка при генерации динамического отчёта",
        "Произошла ошибка сервера при генерации динамического отчёта. Пожалуйста, обратитесь к системному администратору"
      );
    },
    async findOrCreateReport() {
      this.isLoading = true;

      await this.findOrCreateDynamicReport({
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        categoryId: this.categoryId,
        nomenclatureCode: this.selectedNomenclature?.code
      } as DynamicReportCreationArguments);
      await this.fetchDynamicReportData();
      if (!this.dynamicReport) this.showErrorToast();
      this.isLoading = false;
    },
    async createNewReport() {
      this.isLoading = true;
      await this.resetDynamicReport();
      await this.createNewDynamicReport({
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        categoryId: this.categoryId,
        nomenclatureCode: this.selectedNomenclature?.code
      } as DynamicReportCreationArguments);
      await this.fetchDynamicReportData();
      if (!this.dynamicReport) this.showErrorToast();
      this.isLoading = false;
    },
    async onReloadReportButtonClick() {
      await this.createNewReport();
    },
    async onLoadReportButtonClick() {
      await this.findOrCreateReport();
    },
    async onDownloadReportButtonClick() {
      if (!this.dynamicReport) return;
      this.downloadFile(this.dynamicReport.id);
    },
    updateDownloadButton() {
      (<HTMLAnchorElement>this.$refs.downloadButton).href = this.downloadLink;
    },
    downloadFile(reportId: number) {
      this.downloadReportId = reportId;
      this.updateDownloadButton();
      (<HTMLAnchorElement>this.$refs.downloadButton).click();
    },
    async reset() {
      await this.resetDynamicReport();
    }
  },
  watch: {
    async categoryId() {
      await this.reset();
    }
  },
  async beforeMount() {
    await this.resetDynamicReport();
    await this.fetchDynamicCategories();
    this.nomenclaturesList = await DynamicReportsApiService.instance.getNomenclaturesList();
    this.selectedNomenclature = this.nomenclaturesList[0];
  },
  setup() {
    const showErrorToastDialog = useShowErrorToast();
    return {
      showErrorToastDialog
    };
  }
});

<template>
  <Dialog
    v-model:visible="showModel"
    :style="{ width: '450px' }"
    header="Подтвердить действие"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1rem" />
      <span>{{ question }}</span>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        class="p-button-text"
        @click="handleDeclineButtonClick"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        class="p-button-text"
        @click="handleConfirmButtonClick"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default defineComponent({
  name: "ConfirmationDialog",
  data() {
    return {
      showModel: false,
    };
  },
  emits: ["confirm", "decline"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleConfirmButtonClick() {
      this.$emit("confirm");
    },
    handleDeclineButtonClick() {
      this.$emit("decline");
    },
  },
  watch: {
    showModel(newValue) {
      if (!newValue) this.$emit("decline", newValue);
    },
    show(newValue) {
      this.showModel = newValue;
    },
  },
  mounted() {
    this.showModel = this.show;
  },
  components: { Dialog, Button },
});
</script>

<style lang="scss" scoped></style>

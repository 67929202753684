import { Getters } from "vuex-smart-module";
import DynamicReportCategoriesState from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesState";
import DynamicReportCategoryModel from "@/models/DynamicReportCategoryModel";

export default class DynamicReportCategoriesGetters extends Getters<DynamicReportCategoriesState> {
  get dynamicCategories(): DynamicReportCategoryModel[] | undefined {
    return this.state.dynamicCategories;
  }

  get loaded(): boolean {
    return this.state.loaded;
  }
}

<template>
  <XlsxWorksheet :worksheet="worksheet" :scaling="scaling" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import XlsxWorksheet from "@/components/XlsxModelView/XlsxWorksheet.vue";
import ScalingModel from "@/models/Xlsx/ScalingModel";
import { WorkbookModel, WorksheetModel } from "@xlsx-model/models";

export default defineComponent({
  name: "XlsxModelView",
  data() {
    return {
      scaling: {
        height: 1,
        width: 2,
        font: 1,
      } as ScalingModel,
    };
  },
  components: { XlsxWorksheet },
  props: {
    workbook: {
      type: Object as PropType<WorkbookModel>,
      required: true,
    },
  },
  computed: {
    worksheet(): WorksheetModel {
      return this.workbook.worksheets[0];
    },
  },
});
</script>

<style lang="scss" scoped></style>

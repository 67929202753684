<template>
  <div class="home" v-if="loadedHome">
    <Header :phone-number="phone" :email="email" />
    <div class="img-container">
      <img src="../static/logo.svg" alt="LOGO" />
    </div>
  </div>
</template>

<script lang="ts">
import Header from "@/components/Home/Header.vue";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";
import { homePageModuleMapper } from "@/store/modules/homePage";

export default defineComponent({
  name: "Home",
  async mounted() {
    if (!this.loadedHome) await this.fetchHomePageInfo();
    if (!this.loadedAuth) await this.fetchCurrentState();
  },
  computed: {
    ...homePageModuleMapper.mapGetters(["phone", "email"]),
    ...homePageModuleMapper.mapGetters({
      loadedHome: "loaded",
    }),
    ...authorizationModuleMapper.mapGetters({
      loadedAuth: "loaded",
    }),
  },
  methods: {
    ...homePageModuleMapper.mapActions(["fetchHomePageInfo"]),
    ...authorizationModuleMapper.mapActions(["fetchCurrentState"]),
  },
  components: {
    Header,
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

.home {
  font-family: Roboto, sans-serif;

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 500px;
      margin-top: 180px;
    }
  }
}
</style>

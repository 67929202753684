import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export default abstract class ApiServiceBase {
  private readonly _axios: AxiosInstance;

  protected constructor(requestConfig: AxiosRequestConfig) {
    this._axios = axios.create(requestConfig);
  }

  public async sendRequest(
    requestConfig: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this._axios.request(requestConfig);
  }
}

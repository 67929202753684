import { Actions } from "vuex-smart-module";
import DynamicReportCategoriesMutations from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesMutations";
import DynamicReportCategoriesGetters from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesGetters";
import DynamicReportCategoriesState from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesState";
import DynamicReportCategoriesApiService from "@/api/DynamicReportCategoriesApiService";

export default class DynamicReportCategoriesActions extends Actions<
  DynamicReportCategoriesState,
  DynamicReportCategoriesGetters,
  DynamicReportCategoriesMutations,
  DynamicReportCategoriesActions
> {
  async fetchDynamicCategories(): Promise<void> {
    const apiService = DynamicReportCategoriesApiService.instance;
    const categories = await apiService.getDynamicCategories();

    if (!categories) return;

    this.commit("setDynamicCategories", categories);
    this.commit("setLoaded", true);
  }
}

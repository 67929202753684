
import Contact from "@/components/Home/Contact.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Contacts",
  data() {
    return {
      iconStyle: {
        fontSize: "0.6rem",
        color: "#7b7b7b",
      },
      contactStyle: {
        fontSize: "15px",
        marginRight: "30px",
        color: "#7b7b7b",
      },
    };
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    Contact,
  },
});

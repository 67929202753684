import { useToast } from "primevue/usetoast";

export default function useShowErrorToast(): (
  summary: string,
  detail: string
) => void {
  const toast = useToast();

  return (summary: string, detail: string) => {
    toast.add({
      severity: "error",
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
}

import { Mutations } from "vuex-smart-module";
import DynamicReportCategoriesState from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesState";
import DynamicReportCategoryModel from "@/models/DynamicReportCategoryModel";

export default class DynamicReportCategoriesMutations extends Mutations<DynamicReportCategoriesState> {
  setDynamicCategories(payload: DynamicReportCategoryModel[]): void {
    this.state.dynamicCategories = payload;
  }

  setLoaded(payload: boolean): void {
    this.state.loaded = payload;
  }
}

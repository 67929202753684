import { WorksheetModel } from "@xlsx-model/models";
import Row from "@/models/Xlsx/Row";
import { Stack } from "typescript-collections";

export function prepareXlsxRows(worksheet: WorksheetModel): Row[] {
  const rowCount = worksheet.tableMarkup.rowsCount;

  const rows: Row[] = [] as Row[];

  let previousLevel = 0;
  const masterRows = new Stack<Row>();

  for (let i = 0; i < rowCount; i++) {
    const rowModel = worksheet.rows.getValue(i);
    const rowMarkup = worksheet.tableMarkup.rows.getValue(i);

    const row = new Row(i);
    row.model = rowModel;
    row.markup = rowMarkup;

    if (rowMarkup && rowMarkup.level !== undefined) {
      let isHidden = masterRows.peek()?.collapsed;

      const newLevel = rowMarkup.level;

      if (newLevel > previousLevel) {
        const previousRow = rows[i - 1];

        previousRow.isMaster = true;
        previousRow.collapsed = rowMarkup.collapsed || false;

        if (previousRow.hidden) isHidden = true;
        else isHidden = previousRow.collapsed;

        masterRows.push(previousRow);
      }

      if (newLevel < previousLevel) {
        const levelDifference = previousLevel - newLevel;
        for (let a = 0; a < levelDifference; a++) masterRows.pop();
        isHidden =
          masterRows.peek()?.collapsed || masterRows.peek()?.hidden || false;
      }

      previousLevel = newLevel;

      const masterRow = masterRows.peek();
      if (masterRow) {
        row.hidden = isHidden || false;
        masterRow.dependentRows.push(row);
      }
    }

    rows.push(row);
  }
  return rows;
}

<template>
  <div class="menu">
    <div class="container">
      <Menubar :key="menuKey" :model="menuItems">
        <template #start>
          <div style="padding: 0 10px">
            Здравствуйте,
            <b>{{ currentUser.firstName }} {{ currentUser.secondName }}</b
            >!
          </div>
        </template>
        <template #end>
          <div class="button-wrapper">
            <Button
              label="Сообщения"
              :badge="
                uncheckedReportsCount ? uncheckedReportsCount.toString() : null
              "
              class="p-mr-2"
              @click="handleMessagesButtonClick"
            />
            <Button
              icon="pi pi-power-off"
              class="p-button-danger"
              @click="handleLogoutButtonClick"
            />
          </div>
        </template>
      </Menubar>
    </div>
  </div>
</template>

<script lang="ts">
import Menubar from "primevue/menubar";
import Button from "primevue/button";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";
import { reportsModuleMapper } from "@/store/modules/reports";
import { dynamicReportCategoriesModuleMapper } from "@/store/modules/dynamicReportCategories";
import ReportCategoryModel from "@/models/ReportCategoryModel";

interface MenuItem {
  label?: string;
  icon?: string;
  command?: () => void;
  items?: MenuItem[];
}

export default defineComponent({
  name: "Menu",
  data() {
    return {
      menuItems: [] as MenuItem[],
      menuKey: 0,
    };
  },
  computed: {
    ...authorizationModuleMapper.mapGetters(["currentUser"]),
    ...dynamicReportCategoriesModuleMapper.mapGetters({
      categoriesLoaded: "loaded",
      categories: "dynamicCategories",
    }),
    ...reportsModuleMapper.mapGetters(["uncheckedReportsCount"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["logOut"]),
    ...dynamicReportCategoriesModuleMapper.mapActions([
      "fetchDynamicCategories",
    ]),
    ...reportsModuleMapper.mapActions(["fetchUncheckedReportsCount"]),
    loadMenuItems() {
      const categories: ReportCategoryModel[] = this.categories || [];

      const categoriesItems = categories.map<MenuItem>(
        (value: ReportCategoryModel) => {
          return {
            label: value.name,
            icon: "pi pi-file",
            command: () => {
              this.$router.push({
                path: "/cabinet/dynamicReport",
                query: { categoryId: value.id },
              });
            },
          };
        }
      );

      categoriesItems.push({
        label: "Остальные отчёты",
        icon: "pi pi-file",
        command: () => {
          this.$router.push({
            path: "/cabinet/reports",
          });
        },
      });

      let adminMenuItem: undefined | MenuItem;

      if (
        this.currentUser &&
        this.currentUser.role &&
        this.currentUser.role.privilegeLevel >= 2
      ) {
        adminMenuItem = {
          label: "Администрирование",
          items: [
            {
              label: "Пользователи",
              icon: "pi pi-user",
              command: () => {
                this.$router.push("/cabinet/users");
              },
            },
          ],
        };
      }
      this.menuItems = [
        {
          label: "На главную",
          icon: "pi pi-angle-left",
          command: () => {
            this.$router.push("/");
          },
        },
        {
          label: "Отчёты",
          icon: "pi pi-folder",
          items: categoriesItems,
        },
      ];

      if (adminMenuItem) this.menuItems.push(adminMenuItem);
    },
    handleLogoutButtonClick() {
      this.logOut();
      this.$router.go(0);
    },
    updateMenuBar() {
      this.menuKey++;
    },
    handleMessagesButtonClick() {
      this.$router.push({
        path: "/cabinet/messages",
        query: { updateId: Date.now() },
      });
    },
  },
  async mounted() {
    if (!this.categoriesLoaded) await this.fetchDynamicCategories();
    if (this.uncheckedReportsCount === undefined)
      await this.fetchUncheckedReportsCount();
    await this.loadMenuItems();
    this.updateMenuBar();
  },
  components: {
    Menubar,
    Button,
  },
});
</script>

<style lang="scss" scoped>
.menu {
  margin-top: 30px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  button {
    margin: 0 3px;
  }
}
</style>

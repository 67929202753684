<template>
  <div class="user-info">
    <div class="greeting">
      Здравствуйте,&#160;<b
        >{{ currentUser.firstName }} {{ currentUser.secondName }}</b
      >!
    </div>
    <Button
      class="button"
      label="Личный кабинет"
      @click="handlePersonalCabinetButtonClick"
    />
    <Button
      class="button p-button-outlined p-button-danger"
      icon="pi pi-power-off"
      @click="handleLogoutButtonClick"
    />
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "UserInfo",
  computed: {
    ...authorizationModuleMapper.mapGetters(["currentUser"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["logOut"]),
    handlePersonalCabinetButtonClick() {
      this.$router.push({ path: "/cabinet" });
    },
    async handleLogoutButtonClick() {
      await this.logOut();
      this.$router.go(0);
    },
  },
  components: {
    Button,
  },
});
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  .greeting {
    font-size: 15px;
    color: #7b7b7b;
    margin-right: 10px;
  }

  .button {
    font-size: 15px;
    margin: 5px;
    height: 40px;
  }
}
</style>

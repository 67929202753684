import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Report from "@/views/PersonalCabinet/Report.vue";
import Reports from "@/views/PersonalCabinet/Reports.vue";
import Messages from "@/views/PersonalCabinet/Messages.vue";
import PersonalCabinet from "@/views/PersonalCabinet.vue";
import AuthorizationApiService from "@/api/AuthorizationApiService";
import Users from "@/views/PersonalCabinet/Users.vue";
import DynamicReport from "@/views/PersonalCabinet/DynamicReport.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/cabinet",
    name: "Personal cabinet",
    component: PersonalCabinet,
    beforeEnter: async (to, from, next) => {
      const apiService = AuthorizationApiService.instance;
      const isLoggedIn = await apiService.isLoggedIn();
      if (isLoggedIn) next();
      else next("/");
    },
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          next("/cabinet/messages");
        },
      },
      {
        path: "messages",
        name: "Messages",
        component: Messages,
        props: (route) => {
          let updateId: number | undefined = undefined;

          if (route.query?.updateId !== null)
            updateId = parseInt(<string>route.query?.updateId);

          return {
            updateId,
          };
        },
      },
      {
        path: "report",
        name: "Report",
        component: Report,
        props: (route) => {
          let reportId: number | undefined = undefined;

          if (route.query?.reportId !== null)
            reportId = parseInt(<string>route.query?.reportId);

          return {
            reportId,
          };
        },
      },
      {
        path: "reports",
        name: "Reports",
        component: Reports,
        props: (route) => {
          let categoryId: number | undefined = undefined;
          let updateId: number | undefined = undefined;

          if (route.query?.categoryId !== null)
            categoryId = parseInt(<string>route.query?.categoryId);

          if (route.query?.updateId !== null)
            updateId = parseInt(<string>route.query?.updateId);

          return {
            categoryId,
            updateId,
          };
        },
      },
      {
        path: "dynamicReport",
        name: "Dynamic Report",
        component: DynamicReport,
        props: (route) => {
          let categoryId: number | undefined = undefined;

          if (route.query?.categoryId !== null)
            categoryId = parseInt(<string>route.query?.categoryId);

          return {
            categoryId,
          };
        },
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
    ] as Array<RouteRecordRaw>,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

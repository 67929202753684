import ReportModel from "@/models/ReportModel";
import { WorkbookModel } from "@xlsx-model/models";

export default class ReportsState {
  reports?: ReportModel[];
  uncheckedReportsCount?: number;

  report?: ReportModel;
  reportData?: WorkbookModel;

  category?: number;
  isChecked?: boolean;
}

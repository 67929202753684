import HomePageState from "./homePageState";
import { Mutations } from "vuex-smart-module";
import { HomePageInfoModel } from "@/models/HomePageInfoModel";

export default class HomePageMutations extends Mutations<HomePageState> {
  setModel(payload: HomePageInfoModel): void {
    this.state.homePageInfoModel = payload;
  }

  setLoaded(payload: boolean): void {
    this.state.loaded = payload;
  }
}

import { Getters } from "vuex-smart-module";
import ReportsState from "@/store/modules/reports/reportsState";
import ReportModel from "@/models/ReportModel";
import { WorkbookModel } from "@xlsx-model/models";

export default class ReportsGetters extends Getters<ReportsState> {
  get reports(): ReportModel[] | undefined {
    return this.state.reports;
  }

  get uncheckedReportsCount(): number | undefined {
    return this.state.uncheckedReportsCount;
  }

  get report(): ReportModel | undefined {
    return this.state.report;
  }

  get reportData(): WorkbookModel | undefined {
    return this.state.reportData;
  }
}

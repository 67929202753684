<template>
  <header class="header">
    <div class="container h-100">
      <div class="row align-content-center h-100">
        <div class="col-6">
          <Contacts :email="email" :phone="phoneNumber" />
        </div>
        <div class="col-6">
          <transition name="fade">
            <div v-if="loaded">
              <LoginForm v-if="!isLoggedIn" />
              <UserInfo v-else />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import LoginForm from "@/components/Home/Header/LoginForm.vue";
import Contacts from "@/components/Home/Header/Contacts.vue";
import UserInfo from "@/components/Home/Header/UserInfo.vue";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "Header",
  computed: {
    ...authorizationModuleMapper.mapGetters([
      "isLoggedIn",
      "loaded",
      "currentUser",
    ]),
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    LoginForm,
    Contacts,
    UserInfo,
  },
});
</script>

<style lang="scss" scoped>
header.header {
  height: 100px;

  .fade-enter-leave,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

<template>
  <router-view />
</template>

<style lang="scss">
html {
  font-size: 15px;
  min-width: 1000px;
}
</style>

<template>
  <span
    :class="['report-badge', 'status-' + (checked ? 'checked' : 'unchecked')]"
  >
    {{ checked ? "Проверенный" : "Непроверенный" }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportStatus",
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.report-badge {
  padding: 3px 5px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;

  &.status-checked {
    background-color: #c8e6c9;
    color: #256029;
  }

  &.status-unchecked {
    background-color: #ffcdd2;
    color: #c63737;
  }
}
</style>

<template>
  <div class="datepicker-container">
    <div class="datepicker-group">
      <span class="datepicker-label">От: </span>
      <datepicker
        v-model="fromValue"
        :locale="locale"
        inputFormat="dd.MM.yyyy"
      ></datepicker>
    </div>
    <div class="datepicker-group">
      <span class="datepicker-label">До: </span>
      <datepicker
        v-model="toValue"
        :locale="locale"
        inputFormat="dd.MM.yyyy"
      ></datepicker>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import locale from "date-fns/locale/ru";
import datepicker from "vue3-datepicker";

export default defineComponent({
  name: "DatePeriodPicker",
  data() {
    return {
      fromValue: new Date(),
      toValue: new Date(),
      locale: locale,
    };
  },
  props: {
    from: {
      type: Object as PropType<Date>,
      default: new Date(Date.now()),
    },
    to: {
      type: Object as PropType<Date>,
      default: new Date(Date.now()),
    },
  },
  emits: ["update:from", "update:to"],
  watch: {
    fromValue(newValue) {
      this.$emit("update:from", newValue);
    },
    toValue(newValue) {
      this.$emit("update:to", newValue);
    },
    from(newValue) {
      this.fromValue = newValue;
    },
    to(newValue) {
      this.toValue = newValue;
    },
  },
  mounted() {
    this.fromValue = this.from;
    this.toValue = this.to;
  },
  components: {
    datepicker,
  },
});
</script>
<style lang="scss" scoped>
.datepicker-container {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: 20px auto;
  justify-content: space-around;

  .datepicker-group {
    text-align: center;
  }
}
</style>


import Header from "@/components/Home/Header.vue";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";
import { homePageModuleMapper } from "@/store/modules/homePage";

export default defineComponent({
  name: "Home",
  async mounted() {
    if (!this.loadedHome) await this.fetchHomePageInfo();
    if (!this.loadedAuth) await this.fetchCurrentState();
  },
  computed: {
    ...homePageModuleMapper.mapGetters(["phone", "email"]),
    ...homePageModuleMapper.mapGetters({
      loadedHome: "loaded",
    }),
    ...authorizationModuleMapper.mapGetters({
      loadedAuth: "loaded",
    }),
  },
  methods: {
    ...homePageModuleMapper.mapActions(["fetchHomePageInfo"]),
    ...authorizationModuleMapper.mapActions(["fetchCurrentState"]),
  },
  components: {
    Header,
  },
});

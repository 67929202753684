import { Mutations } from "vuex-smart-module";
import AuthorizationState from "@/store/modules/authorization/authorizationState";
import UserModel from "@/models/UserModel";

export default class AuthorizationMutations extends Mutations<AuthorizationState> {
  setCurrentUser(payload: UserModel | undefined): void {
    this.state.currentUser = payload;
  }

  setIsLoggedIn(payload: boolean): void {
    this.state.isLoggedIn = payload;
  }

  setLoaded(payload: boolean): void {
    this.state.loaded = payload;
  }
}

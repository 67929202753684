import { createMapper, Module } from "vuex-smart-module";
import DynamicReportState from "@/store/modules/dynamicReport/dynamicReportState";
import DynamicReportGetters from "@/store/modules/dynamicReport/dynamicReportGetters";
import DynamicReportMutations from "@/store/modules/dynamicReport/dynamicReportMutations";
import DynamicReportActions from "@/store/modules/dynamicReport/dynamicReportActions";

export const dynamicReportModule = new Module({
  state: DynamicReportState,
  getters: DynamicReportGetters,
  mutations: DynamicReportMutations,
  actions: DynamicReportActions,
});

export const dynamicReportModuleMapper = createMapper(dynamicReportModule);

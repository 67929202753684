import ApiServiceBase from "@/api/ApiServiceBase";
import { apiBaseUrl } from "@/api/api.config";
import ReportModel from "@/models/ReportModel";
import ReportCategoryModel from "@/models/ReportCategoryModel";
import { WorkbookModel } from "@xlsx-model/models";
import { WorkbookModelDeserializer } from "@xlsx-model/serializer";
import moment from "moment";

interface ReportData {
  id: number;
  name: string;
  date: number;
  checked: number;
  category?: {
    id: number;
    name: string;
  };
}

export default class ReportsApiService extends ApiServiceBase {
  private static _instance: ReportsApiService | undefined;

  public static get instance(): ReportsApiService {
    if (!this._instance) this._instance = new ReportsApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/reports",
      withCredentials: true,
    });
  }

  private static parseReportModel(data: ReportData): ReportModel {
    let category: ReportCategoryModel | undefined;

    if (data.category) {
      category = {
        id: data.category.id,
        name: data.category.name,
      } as ReportCategoryModel;
    }

    const period = moment(data.date).format("MM.YYYY");

    return {
      id: data.id,
      name: data.name,
      date: new Date(data.date),
      checked: !!data.checked,
      category: category,
      period: period,
    } as ReportModel;
  }

  private async getAllReportsByFilter(filter: {
    categoryId?: number;
    checked?: boolean;
  }): Promise<ReportModel[] | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getAll",
        params: filter,
      });

      if (response.status !== 200) return undefined;

      return (response.data as ReportData[]).map<ReportModel>(
        (value: ReportData) => {
          return ReportsApiService.parseReportModel(value);
        }
      );
    } catch (e) {
      return undefined;
    }
  }

  async getUncheckedReports(): Promise<ReportModel[] | undefined> {
    return this.getAllReportsByFilter({ checked: false });
  }

  async getReportsByCategory(
    categoryId: number
  ): Promise<ReportModel[] | undefined> {
    return this.getAllReportsByFilter({ categoryId });
  }

  async getUncheckedReportsCount(): Promise<number | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/uncheckedCount",
      });

      if (response.status !== 200) return undefined;

      return response.data.count;
    } catch (e) {
      return undefined;
    }
  }

  async getReportById(reportId: number): Promise<ReportModel | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getById",
        params: {
          id: reportId,
        },
      });

      if (response.status !== 200) return undefined;

      console.log(response.data);

      return ReportsApiService.parseReportModel(<ReportData>response.data);
    } catch (e) {
      return undefined;
    }
  }

  async checkReport(reportId: number): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/check",
        params: {
          id: reportId,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  async getParsedData(reportId: number): Promise<WorkbookModel | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getParsed",
        params: {
          id: reportId,
        },
      });

      if (response.status !== 200) return undefined;

      return new WorkbookModelDeserializer().deserialize(response.data);
    } catch (e) {
      return undefined;
    }
  }

  async deleteReport(reportId: number): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/delete",
        params: {
          id: reportId,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  async getAllReports(): Promise<ReportModel[] | undefined> {
    return this.getAllReportsByFilter({});
  }
}

import { Mutations } from "vuex-smart-module";
import DynamicReportState from "@/store/modules/dynamicReport/dynamicReportState";
import { WorkbookModel } from "@xlsx-model/models";
import DynamicReportModel from "@/models/DynamicReportModel";

export default class DynamicReportMutations extends Mutations<DynamicReportState> {
  setDynamicReportData(payload: WorkbookModel): void {
    this.state.dynamicReportData = payload;
  }

  resetDynamicReportData(): void {
    this.state.dynamicReportData = undefined;
  }

  setDynamicReport(payload: DynamicReportModel): void {
    this.state.dynamicReport = payload;
  }

  resetDynamicReport(): void {
    this.state.dynamicReport = undefined;
  }
}

import { createMapper, Module } from "vuex-smart-module";
import UsersState from "@/store/modules/users/usersState";
import UsersGetters from "@/store/modules/users/usersGetters";
import UsersMutations from "@/store/modules/users/usersMutations";
import UsersActions from "@/store/modules/users/usersActions";

export const usersModule = new Module({
  state: UsersState,
  getters: UsersGetters,
  mutations: UsersMutations,
  actions: UsersActions,
});

export const usersModuleMapper = createMapper(usersModule);


import { defineComponent, PropType } from "vue";
import Dialog from "primevue/dialog";
import UserModel from "@/models/UserModel";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default defineComponent({
  name: "UserChangeDialog",
  data() {
    return {
      userModel: undefined as undefined | UserModel,
      isLoginValid: true,
      isFirstNameValid: true,
      isSecondNameValid: true,
      showModel: false,
    };
  },
  components: { Dialog, InputText, Button },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<UserModel>,
      required: true,
    },
  },
  methods: {
    validateLogin(userModel: UserModel): boolean {
      const matches = userModel.login.match(/[A-z0-9-_]+/);
      return matches !== null && matches[0] === userModel.login;
    },
    validateFirstName(userModel: UserModel): boolean {
      const matches = userModel.firstName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === userModel.firstName;
    },
    validateSecondName(userModel: UserModel): boolean {
      const matches = userModel.secondName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === userModel.secondName;
    },
    validate(): boolean {
      if (!this.userModel) return true;

      this.isLoginValid = this.validateLogin(this.userModel);
      this.isFirstNameValid = this.validateFirstName(this.userModel);
      this.isSecondNameValid = this.validateSecondName(this.userModel);

      return (
        this.isLoginValid && this.isFirstNameValid && this.isSecondNameValid
      );
    },
    save() {
      if (this.validate()) {
        this.$emit("confirm", this.userModel);
      }
    },
    cancel() {
      this.$emit("decline");
    },
  },
  watch: {
    showModel(newValue) {
      if (!newValue) this.$emit("decline");
    },
    show(newValue) {
      this.showModel = newValue;
    },
    user(newValue) {
      this.userModel = newValue;
    },
  },
  mounted() {
    this.userModel = this.user;
    this.showModel = this.show;
  },
  emits: ["confirm", "decline"],
});

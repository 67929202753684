import HomePageState from "@/store/modules/homePage/homePageState";
import { Getters } from "vuex-smart-module";

export default class HomePageGetters extends Getters<HomePageState> {
  get phone(): string | undefined {
    return this.state.homePageInfoModel?.phone;
  }

  get email(): string | undefined {
    return this.state.homePageInfoModel?.email;
  }

  get loaded(): boolean {
    return this.state.loaded;
  }
}

<template>
  <div class="contacts">
    <Contact
      contact-type="phone"
      :value="phone"
      :icon-style="iconStyle"
      :value-style="contactStyle"
    />
    <Contact
      contact-type="email"
      :value="email"
      :icon-style="iconStyle"
      :value-style="contactStyle"
    />
  </div>
</template>

<script lang="ts">
import Contact from "@/components/Home/Contact.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "Contacts",
  data() {
    return {
      iconStyle: {
        fontSize: "0.6rem",
        color: "#7b7b7b",
      },
      contactStyle: {
        fontSize: "15px",
        marginRight: "30px",
        color: "#7b7b7b",
      },
    };
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    Contact,
  },
});
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>

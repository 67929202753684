import { createMapper, Module } from "vuex-smart-module";
import ReportsState from "@/store/modules/reports/reportsState";
import ReportsGetters from "@/store/modules/reports/reportsGetters";
import ReportsMutations from "@/store/modules/reports/reportsMutations";
import ReportsActions from "@/store/modules/reports/reportsActions";

export const reportsModule = new Module({
  state: ReportsState,
  getters: ReportsGetters,
  mutations: ReportsMutations,
  actions: ReportsActions,
});

export const reportsModuleMapper = createMapper(reportsModule);

<template>
  <Dialog
    v-model:visible="showModel"
    :style="{ width: '450px' }"
    header="Изменение пользователя"
    :modal="true"
    class="p-fluid"
    v-if="userModel !== undefined"
  >
    <div class="p-field">
      <label for="login">Логин</label>
      <InputText
        id="login"
        v-model.trim="userModel.login"
        required="true"
        autofocus
        :class="{ 'p-invalid': !isLoginValid }"
      />
      <small class="p-error" v-if="!isLoginValid"
        >Логин имеет недопустимые символы</small
      >
    </div>
    <div class="p-field">
      <label for="firstName">Имя</label>
      <InputText
        id="firstName"
        v-model.trim="userModel.firstName"
        required="true"
        :class="{ 'p-invalid': !isFirstNameValid }"
      />
      <small class="p-error" v-if="!isFirstNameValid"
        >Имя имеет недопустимые символы</small
      >
    </div>
    <div class="p-field">
      <label for="secondName">Фамилия</label>
      <InputText
        id="secondName"
        v-model.trim="userModel.secondName"
        required="true"
        :class="{ 'p-invalid': !isSecondNameValid }"
      />
      <small class="p-error" v-if="!isSecondNameValid"
        >Фамилия имеет недопустимые символы</small
      >
    </div>
    <template #footer>
      <Button
        label="Сохранить"
        icon="pi pi-check"
        class="p-button-text"
        @click="save"
      />
      <Button
        label="Отмена"
        icon="pi pi-times"
        class="p-button-text"
        @click="cancel"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Dialog from "primevue/dialog";
import UserModel from "@/models/UserModel";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

export default defineComponent({
  name: "UserChangeDialog",
  data() {
    return {
      userModel: undefined as undefined | UserModel,
      isLoginValid: true,
      isFirstNameValid: true,
      isSecondNameValid: true,
      showModel: false,
    };
  },
  components: { Dialog, InputText, Button },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<UserModel>,
      required: true,
    },
  },
  methods: {
    validateLogin(userModel: UserModel): boolean {
      const matches = userModel.login.match(/[A-z0-9-_]+/);
      return matches !== null && matches[0] === userModel.login;
    },
    validateFirstName(userModel: UserModel): boolean {
      const matches = userModel.firstName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === userModel.firstName;
    },
    validateSecondName(userModel: UserModel): boolean {
      const matches = userModel.secondName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === userModel.secondName;
    },
    validate(): boolean {
      if (!this.userModel) return true;

      this.isLoginValid = this.validateLogin(this.userModel);
      this.isFirstNameValid = this.validateFirstName(this.userModel);
      this.isSecondNameValid = this.validateSecondName(this.userModel);

      return (
        this.isLoginValid && this.isFirstNameValid && this.isSecondNameValid
      );
    },
    save() {
      if (this.validate()) {
        this.$emit("confirm", this.userModel);
      }
    },
    cancel() {
      this.$emit("decline");
    },
  },
  watch: {
    showModel(newValue) {
      if (!newValue) this.$emit("decline");
    },
    show(newValue) {
      this.showModel = newValue;
    },
    user(newValue) {
      this.userModel = newValue;
    },
  },
  mounted() {
    this.userModel = this.user;
    this.showModel = this.show;
  },
  emits: ["confirm", "decline"],
});
</script>

<style lang="scss" scoped>
.p-field {
  margin: 10px 0;
  label {
    margin-bottom: 10px;
  }
}
</style>

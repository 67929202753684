
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import { defineComponent, PropType } from "vue";
import UserModel, { IUserModel } from "@/models/UserModel";
import ConfirmationDialog from "@/components/PersonalCabinet/ConfirmationDialog.vue";
import UsersApiService from "@/api/UsersApiService";
import UserChangeDialog from "@/components/PersonalCabinet/UserChangeDialog.vue";
import useShowErrorToast from "@/useShowErrorToast";

export default defineComponent({
  name: "UsersTable",
  data() {
    return {
      showChangeUserDialog: false,
      userToChange: undefined as UserModel | undefined,
      deleteUserId: 0,
      showDeleteConfirmation: false,
    };
  },
  components: {
    UserChangeDialog,
    ConfirmationDialog,
    DataTable,
    Column,
    Button,
  },
  props: {
    users: {
      type: Object as PropType<UserModel[]>,
      required: true,
    },
  },
  setup() {
    const showErrorToast = useShowErrorToast();
    return { showErrorToast };
  },
  methods: {
    async confirmDeleteUser() {
      this.showDeleteConfirmation = false;
      const apiService = UsersApiService.instance;
      const result = await apiService.deleteUser(this.deleteUserId);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось удалить пользователя",
          "Произошла ошибка сервера. Не удалось удалить пользователя. Обратитесь к системному администратору"
        );
    },
    async confirmUserChange(userModel: IUserModel) {
      this.showChangeUserDialog = false;
      const apiService = UsersApiService.instance;
      const result = await apiService.updateUser(userModel);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось изменить данные пользователя",
          "Произошла ошибка сервера. Не удалось изменить данные пользователя. Обратитесь к системному администратору"
        );
    },
    handleEditButtonClick(userId: number) {
      const user = this.users.find((value) => value.id == userId);
      if (!user) return;
      this.userToChange = UserModel.from(user);
      this.showChangeUserDialog = true;
    },
    handleDeleteButtonClick(userId: number) {
      this.deleteUserId = userId;
      this.showDeleteConfirmation = true;
    },
  },
});

import { createMapper, Module } from "vuex-smart-module";
import ReportCategoriesState from "@/store/modules/reportCategories/reportCategoriesState";
import ReportCategoriesGetters from "@/store/modules/reportCategories/reportCategoriesGetters";
import ReportCategoriesMutations from "@/store/modules/reportCategories/reportCategoriesMutations";
import ReportCategoriesActions from "@/store/modules/reportCategories/reportCategoriesActions";

export const reportCategoriesModule = new Module({
  state: ReportCategoriesState,
  getters: ReportCategoriesGetters,
  mutations: ReportCategoriesMutations,
  actions: ReportCategoriesActions,
});

export const reportCategoriesModuleMapper = createMapper(
  reportCategoriesModule
);

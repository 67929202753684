<template>
  <div class="report-wrapper" v-if="report && report?.id">
    <div class="report" v-if="report !== undefined">
      <ReportsTable
        :show-goto-button="false"
        :reports="[report]"
        :group-by-date="false"
      />
    </div>
    <div class="report-content" v-if="report !== undefined">
      <h1>Предпросмотр отчёта</h1>
      <div
        class="preview-wrapper"
        v-if="loadPreview && reportData !== undefined"
      >
        <XlsxModelView :workbook="reportData" />
      </div>
      <Button
        class="preview-button"
        label="Загрузить предпросмотр отчёта"
        @click="loadPreview = true"
        v-else
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";
import Button from "primevue/button";

import ReportsTable from "@/components/PersonalCabinet/ReportsTable.vue";
import XlsxModelView from "@/components/XlsxModelView/XlsxModelView.vue";

export default defineComponent({
  name: "Report",
  data() {
    return {
      loadPreview: false,
    };
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...reportsModuleMapper.mapGetters(["report", "reportData"]),
  },
  methods: {
    ...reportsModuleMapper.mapActions([
      "resetReport",
      "fetchReportById",
      "fetchReportData",
    ]),
    async updateReport() {
      await this.fetchReportById(this.reportId);
      if (!this.report?.id) {
        this.$router.back();
        await this.$router.push("/cabinet");
      }
      await this.fetchReportData(this.reportId);
    },
  },
  beforeMount() {
    this.resetReport();
  },
  async mounted() {
    await this.updateReport();
  },
  components: {
    XlsxModelView,
    ReportsTable,
    Button,
  },
});
</script>

<style lang="scss" scoped>
.report-content {
  margin: 50px 0;
}

.report-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-content {
  h1 {
    margin: 50px 0;
    text-align: center;
  }
}

.preview-wrapper {
  overflow: auto;
  width: 100%;
  height: auto;
  display: flex;
}
</style>

<template>
  <div class="outline-control-wrapper">
    <button>
      <i class="pi" :class="[collapsed ? 'pi-plus' : 'pi-minus']"></i>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "OutlineControl",
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.outline-control-wrapper {
  width: 100%;
  height: 100%;
  display: block;

  button {
    background-color: transparent;
    border: 0;
    i {
      color: #000000;
      font-size: 15px;
      transition: 0.25s ease-in-out;
      &:hover {
        color: #3f3f3f;
      }
    }
  }
}
</style>

import { Actions } from "vuex-smart-module";
import AuthorizationState from "@/store/modules/authorization/authorizationState";
import AuthorizationGetters from "@/store/modules/authorization/authorizationGetters";
import AuthorizationMutations from "@/store/modules/authorization/authorizationMutations";
import AuthorizationApiService from "@/api/AuthorizationApiService";
import UserModel from "@/models/UserModel";

export default class AuthorizationActions extends Actions<
  AuthorizationState,
  AuthorizationGetters,
  AuthorizationMutations,
  AuthorizationActions
> {
  async fetchCurrentState(): Promise<void> {
    const apiService = AuthorizationApiService.instance;
    const isLoggedIn = await apiService.isLoggedIn();

    if (!isLoggedIn) {
      this.commit("setIsLoggedIn", false);
      this.commit("setCurrentUser", undefined);
      this.commit("setLoaded", true);
      return;
    }

    const loggedInUser = await apiService.loggedInUser();
    if (!loggedInUser) return;

    this.commit("setIsLoggedIn", true);
    this.commit("setCurrentUser", loggedInUser);
    this.commit("setLoaded", true);
  }

  async logIn(payload: { login: string; password: string }): Promise<boolean> {
    const apiService = AuthorizationApiService.instance;
    const user: UserModel | undefined = await apiService.logIn(
      payload.login,
      payload.password
    );

    if (!user) return false;

    this.commit("setIsLoggedIn", true);
    this.commit("setCurrentUser", user);

    return true;
  }

  async logOut(): Promise<void> {
    const apiService = AuthorizationApiService.instance;
    await apiService.logOut();

    this.commit("setIsLoggedIn", false);
    this.commit("setCurrentUser", undefined);
  }
}


import { defineComponent, PropType } from "vue";
import { CellModel, ColumnMarkup } from "@xlsx-model/models";
import ScalingModel from "@/models/Xlsx/ScalingModel";

export default defineComponent({
  name: "XlsxCell",
  props: {
    cellModel: {
      type: Object as PropType<CellModel>,
      required: true,
    },
    columnMarkup: {
      type: Object as PropType<ColumnMarkup>,
      required: false,
    },
    scaling: {
      type: Object as PropType<ScalingModel>,
      required: false,
    },
  },
  methods: {
    parseColor(color: string): [number, number, number, number] | undefined {
      const regex = /#([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})/;
      const results = color.match(regex);
      if (!results) return undefined;

      const a = parseInt(results[1], 16);
      const r = parseInt(results[2], 16);
      const g = parseInt(results[3], 16);
      const b = parseInt(results[4], 16);

      return [r, g, b, a];
    },
    checkColorDarkness(color: string) {
      const [r, g, b] = this.parseColor(color) || [255, 255, 255];

      let darkness = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return darkness >= 0.4;
    },
  },
  computed: {
    color(): string | undefined {
      const color = this.cellModel.style.fillColor;
      if (!color) return undefined;

      if (color.length === 7) {
        return color;
      } else {
        const [r, g, b, a] = this.parseColor(color) || [255, 255, 255, 255];
        return `rgba(${r},${g}, ${b}, ${a})`;
      }
    },
    textColor(): string {
      const color = this.cellModel.style.fillColor;
      if (!color) return "#000000";

      if (this.checkColorDarkness(color)) {
        return "#ffffff";
      } else return "#000000";
    },
  },
});

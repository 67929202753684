
import LoginForm from "@/components/Home/Header/LoginForm.vue";
import Contacts from "@/components/Home/Header/Contacts.vue";
import UserInfo from "@/components/Home/Header/UserInfo.vue";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "Header",
  computed: {
    ...authorizationModuleMapper.mapGetters([
      "isLoggedIn",
      "loaded",
      "currentUser",
    ]),
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  components: {
    LoginForm,
    Contacts,
    UserInfo,
  },
});

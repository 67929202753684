<template>
  <a
    v-show="false"
    :key="downloadReportId"
    :href="downloadLink"
    ref="downloadButton"
    download
  />
  <div class="dynamic-report-container" v-if="currentCategory !== undefined">
    <div class="title-group">
      <h1>Динамический отчёт</h1>
      <p>Категория: {{ currentCategory.name }}</p>
    </div>

    <DatePeriodPicker
      v-if="currentCategory.usePeriod && !isLoading"
      v-model:from="dateFrom"
      v-model:to="dateTo"
    />
    <div
      class="nomenclature-picker"
      v-if="currentCategory.useNomenclatureList && !isLoading"
    >
      <h5>Выберите номенклатуру из списка:</h5>
      <Dropdown
        class="picker"
        :options="filteredNomenclatureList"
        v-model="selectedNomenclature"
        optionLabel="name"
      >
        <template #header>
          <div class="dropdown-filter-input-container">
            <span class="p-input-icon-right">
              <i class="pi pi-search"></i>
              <InputText class="dropdown-filter-input" v-model="nomenclatureFilter" />
            </span>
          </div>
        </template>
      </Dropdown>
    </div>
    <Button
      class="generate-report-button"
      v-if="dynamicReportData === undefined && !isLoading"
      @click="onLoadReportButtonClick"
    >Сгенерировать отчёт
    </Button>
    <ProgressSpinner v-else-if="isLoading" />
    <div class="dynamic-report-wrapper" v-else>
      <div class="dynamic-report-buttons">
        <Button class="report-button" @click="onReloadReportButtonClick"
        >Сгенерировать новый отчёт
        </Button>
        <Button
          class="report-button p-button-success"
          @click="onDownloadReportButtonClick"
        >Загрузить отчёт
        </Button>
      </div>
      <XlsxModelView :workbook="dynamicReportData" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import XlsxModelView from "@/components/XlsxModelView/XlsxModelView.vue";
import DatePeriodPicker from "@/components/PersonalCabinet/DatePeriodPicker.vue";

import { dynamicReportModuleMapper } from "@/store/modules/dynamicReport";
import { dynamicReportCategoriesModuleMapper } from "@/store/modules/dynamicReportCategories";

import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { apiBaseUrl } from "@/api/api.config";
import useShowErrorToast from "@/useShowErrorToast";
import DynamicReportCategoryModel from "@/models/DynamicReportCategoryModel";
import { NomenclatureModel } from "@/models/NomenclatureModel";
import DynamicReportsApiService, {
  DynamicReportCreationArguments
} from "@/api/DynamicReportsApiService";

export default defineComponent({
  name: "DynamicReport",
  data() {
    return {
      isLoading: false,
      downloadReportId: 0,
      dateFrom: new Date(Date.now()),
      dateTo: new Date(Date.now()),
      nomenclaturesList: [] as NomenclatureModel[],
      selectedNomenclature: undefined as NomenclatureModel | undefined,
      nomenclatureFilter: ""
    };
  },
  components: {
    XlsxModelView,
    Button,
    ProgressSpinner,
    DatePeriodPicker,
    Dropdown,
    InputText
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    }
  },
  computed: {
    downloadLink(): string {
      return (
        apiBaseUrl +
        "/dynamicReports/downloadOriginal?id=" +
        this.downloadReportId.toString()
      );
    },
    ...dynamicReportModuleMapper.mapGetters([
      "dynamicReportData",
      "dynamicReport"
    ]),
    ...dynamicReportCategoriesModuleMapper.mapGetters(["dynamicCategories"]),
    currentCategory(): DynamicReportCategoryModel | undefined {
      const categories: undefined | DynamicReportCategoryModel[] = this
        .dynamicCategories;

      if (categories === undefined) return undefined;

      const filteringResult = categories.find((value) => {
        return value.id === this.categoryId;
      });

      if (filteringResult === undefined) return undefined;

      return filteringResult;
    },
    filteredNomenclatureList(): NomenclatureModel[] {
      return this.nomenclaturesList.filter((item) => {
        return item.name.includes(this.nomenclatureFilter);
      });
    }
  },
  methods: {
    ...dynamicReportModuleMapper.mapActions([
      "resetDynamicReport",
      "createNewDynamicReport",
      "findOrCreateDynamicReport",
      "fetchDynamicReportData"
    ]),
    ...dynamicReportCategoriesModuleMapper.mapActions([
      "fetchDynamicCategories"
    ]),
    showErrorToast() {
      this.showErrorToastDialog(
        "Произошла ошибка при генерации динамического отчёта",
        "Произошла ошибка сервера при генерации динамического отчёта. Пожалуйста, обратитесь к системному администратору"
      );
    },
    async findOrCreateReport() {
      this.isLoading = true;

      await this.findOrCreateDynamicReport({
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        categoryId: this.categoryId,
        nomenclatureCode: this.selectedNomenclature?.code
      } as DynamicReportCreationArguments);
      await this.fetchDynamicReportData();
      if (!this.dynamicReport) this.showErrorToast();
      this.isLoading = false;
    },
    async createNewReport() {
      this.isLoading = true;
      await this.resetDynamicReport();
      await this.createNewDynamicReport({
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
        categoryId: this.categoryId,
        nomenclatureCode: this.selectedNomenclature?.code
      } as DynamicReportCreationArguments);
      await this.fetchDynamicReportData();
      if (!this.dynamicReport) this.showErrorToast();
      this.isLoading = false;
    },
    async onReloadReportButtonClick() {
      await this.createNewReport();
    },
    async onLoadReportButtonClick() {
      await this.findOrCreateReport();
    },
    async onDownloadReportButtonClick() {
      if (!this.dynamicReport) return;
      this.downloadFile(this.dynamicReport.id);
    },
    updateDownloadButton() {
      (<HTMLAnchorElement>this.$refs.downloadButton).href = this.downloadLink;
    },
    downloadFile(reportId: number) {
      this.downloadReportId = reportId;
      this.updateDownloadButton();
      (<HTMLAnchorElement>this.$refs.downloadButton).click();
    },
    async reset() {
      await this.resetDynamicReport();
    }
  },
  watch: {
    async categoryId() {
      await this.reset();
    }
  },
  async beforeMount() {
    await this.resetDynamicReport();
    await this.fetchDynamicCategories();
    this.nomenclaturesList = await DynamicReportsApiService.instance.getNomenclaturesList();
    this.selectedNomenclature = this.nomenclaturesList[0];
  },
  setup() {
    const showErrorToastDialog = useShowErrorToast();
    return {
      showErrorToastDialog
    };
  }
});
</script>

<style lang="scss" scoped>
.dynamic-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  .title-group {
    text-align: center;
  }

  .dynamic-report-wrapper {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.generate-report-button {
  margin: 0 auto;
}

.report-button {
  margin: 5px;
}

.dynamic-report-buttons {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nomenclature-picker {
  text-align: center;
  width: 400px;
  margin: 20px 0;

  .picker {
    width: 100%;
  }
}
</style>


<style lang="scss">
.dropdown-filter-input-container,
span.p-input-icon-right {
  width: 100%;
  box-sizing: border-box;
  padding: 2px;

  .dropdown-filter-input {
    width: 100%;
    height: 100%;
  }
}
</style>
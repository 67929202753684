import RoleModel, { IRoleModel } from "@/models/RoleModel";

export interface IUserModel {
  id: number;
  firstName: string;
  secondName: string;
  login: string;
  role?: IRoleModel;
}

export default class UserModel implements IUserModel {
  id: number;
  firstName: string;
  secondName: string;
  login: string;
  role?: RoleModel;

  public static from(value: IUserModel): UserModel {
    let role: RoleModel | undefined;
    if (value.role) {
      role = RoleModel.from(value.role);
    }
    return new UserModel(
      value.id,
      value.firstName,
      value.secondName,
      value.login,
      role
    );
  }

  constructor(
    id: number,
    firstName: string,
    secondName: string,
    login: string,
    role?: RoleModel
  ) {
    this.id = id;
    this.firstName = firstName;
    this.secondName = secondName;
    this.login = login;
    this.role = role;
  }
}

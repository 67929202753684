import { HomePageInfoModel } from "@/models/HomePageInfoModel";
import { apiBaseUrl } from "@/api/api.config";
import ApiServiceBase from "@/api/ApiServiceBase";

export default class HomePageApiService extends ApiServiceBase {
  private static _instance: HomePageApiService | undefined;

  public static get instance(): HomePageApiService {
    if (!this._instance) this._instance = new HomePageApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/home",
    });
  }

  public async getHomePageInfo(): Promise<HomePageInfoModel | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/info",
      });
      if (response.status != 200) return undefined;

      return <HomePageInfoModel>response.data;
    } catch (e) {
      return undefined;
    }
  }
}

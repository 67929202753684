
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "LoginForm",
  data() {
    return {
      login: "",
      password: "",
      inputStyle: {
        margin: "5px",
      },
    };
  },
  computed: {
    ...authorizationModuleMapper.mapGetters(["currentUser", "isLoggedIn"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["logIn"]),
    async tryLogIn() {
      await this.logIn({ login: this.login, password: this.password });
      if (!this.isLoggedIn) {
        this.showInvalidDataToast();
        this.login = "";
        this.password = "";
      }
    },
    async handleButtonClick() {
      await this.tryLogIn();
    },
    async handleKeyboardPress(e: KeyboardEvent) {
      if (e.key === "Enter") await this.tryLogIn();
    },
  },
  setup: () => {
    const toast = useToast();

    const showInvalidDataToast = () => {
      toast.add({
        severity: "error",
        summary: "Неверные данные для входа",
        detail:
          "Вы ввели неверные данные для входа. Проверьте данные и повторите попытку.",
        life: 3000,
      });
    };

    return { showInvalidDataToast };
  },
  components: {
    InputText,
    Password,
    Button,
    Toast,
  },
});

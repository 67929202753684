import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueCookieNext } from "vue-cookie-next";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "bootstrap/scss/bootstrap.scss";

const app = createApp(App);

app.use(PrimeVue, { ripple: true });
app.use(ToastService);

app.use(store);
app.use(router);
app.use(VueCookieNext);

app.mount("#app");

VueCookieNext.config({
  expire: "7d",
});

import ApiServiceBase from "@/api/ApiServiceBase";
import { apiBaseUrl } from "@/api/api.config";
import UserModel, { IUserModel } from "@/models/UserModel";

export default class UsersApiService extends ApiServiceBase {
  private static _instance: UsersApiService | undefined;

  public static get instance(): UsersApiService {
    if (!this._instance) this._instance = new UsersApiService();
    return this._instance;
  }

  constructor() {
    super({
      withCredentials: true,
      baseURL: apiBaseUrl + "/users",
    });
  }

  async getAllUsers(): Promise<UserModel[] | undefined> {
    try {
      const response = await this.sendRequest({
        method: "get",
        url: "/getAll",
      });
      if (response.status !== 200) return undefined;

      return (response.data as IUserModel[]).map<UserModel>(
        (value: IUserModel) => {
          return UserModel.from(value);
        }
      );
    } catch (e) {
      return undefined;
    }
  }

  async registerUser(
    login: string,
    password: string,
    firstName: string,
    secondName: string
  ): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "POST",
        url: "/register",
        data: {
          login,
          password,
          firstName,
          secondName,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  async deleteUser(id: number): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/delete?id=" + id.toString(),
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }

  async updateUser(userModel: IUserModel): Promise<boolean> {
    try {
      const response = await this.sendRequest({
        method: "post",
        url: "/update",
        params: {
          id: userModel.id,
        },
        data: {
          login: userModel.login,
          firstName: userModel.firstName,
          secondName: userModel.secondName,
        },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  }
}

<template>
  <Toast position="top-left" />
  <div class="login-form">
    <InputText
      class="form-input p-inputtext-sm"
      v-model="login"
      placeholder="Логин"
      @keyup.prevent="handleKeyboardPress"
      :style="inputStyle"
    ></InputText>
    <Password
      class="p-inputtext-sm"
      v-model="password"
      placeholder="Пароль"
      :feedback="false"
      inputClass="form-input"
      @keyup.prevent="handleKeyboardPress"
      toggleMask
    ></Password>
    <Button
      class="p-button-outlined form-button"
      label="Войти"
      @click="handleButtonClick"
    />
  </div>
</template>

<script lang="ts">
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "LoginForm",
  data() {
    return {
      login: "",
      password: "",
      inputStyle: {
        margin: "5px",
      },
    };
  },
  computed: {
    ...authorizationModuleMapper.mapGetters(["currentUser", "isLoggedIn"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["logIn"]),
    async tryLogIn() {
      await this.logIn({ login: this.login, password: this.password });
      if (!this.isLoggedIn) {
        this.showInvalidDataToast();
        this.login = "";
        this.password = "";
      }
    },
    async handleButtonClick() {
      await this.tryLogIn();
    },
    async handleKeyboardPress(e: KeyboardEvent) {
      if (e.key === "Enter") await this.tryLogIn();
    },
  },
  setup: () => {
    const toast = useToast();

    const showInvalidDataToast = () => {
      toast.add({
        severity: "error",
        summary: "Неверные данные для входа",
        detail:
          "Вы ввели неверные данные для входа. Проверьте данные и повторите попытку.",
        life: 3000,
      });
    };

    return { showInvalidDataToast };
  },
  components: {
    InputText,
    Password,
    Button,
    Toast,
  },
});
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
</style>

<style lang="scss">
header.header {
  .login-form {
    .form-input,
    .form-button {
      font-size: 14px;
      height: 40px;
      margin: 5px;
    }

    .form-input {
      width: 180px;
    }

    .form-button {
      width: 100px;
    }

    .pi {
      right: 12px;
    }
  }
}
</style>

import HomePageState from "@/store/modules/homePage/homePageState";
import HomePageGetters from "@/store/modules/homePage/homePageGetters";
import HomePageMutations from "@/store/modules/homePage/homePageMutations";

import { Actions } from "vuex-smart-module";
import HomePageApiService from "@/api/HomePageApiService";

export default class HomePageActions extends Actions<
  HomePageState,
  HomePageGetters,
  HomePageMutations,
  HomePageActions
> {
  async fetchHomePageInfo(): Promise<void> {
    const apiService = HomePageApiService.instance;
    const info = await apiService.getHomePageInfo();

    if (!info) return;

    this.commit("setModel", info);
    this.commit("setLoaded", true);
  }
}

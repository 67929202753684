import { RowMarkup, RowModel } from "@xlsx-model/models";

export default class Row {
  id: number;

  model?: RowModel;
  markup?: RowMarkup;
  hidden: boolean;
  collapsed?: boolean;

  isMaster: boolean;
  dependentRows: Row[];

  constructor(id: number) {
    this.id = id;
    this.hidden = false;
    this.isMaster = false;
    this.dependentRows = [] as Row[];
  }
}

import { Actions } from "vuex-smart-module";
import ReportsState from "@/store/modules/reports/reportsState";
import ReportsMutations from "@/store/modules/reports/reportsMutations";
import ReportsGetters from "@/store/modules/reports/reportsGetters";
import ReportsApiService from "@/api/ReportsApiService";

export default class ReportsActions extends Actions<
  ReportsState,
  ReportsGetters,
  ReportsMutations,
  ReportsActions
> {
  resetReport(): void {
    this.commit("setReport", undefined);
  }

  resetReports(): void {
    this.commit("setReports", undefined);
  }

  async checkReport(reportId: number): Promise<boolean> {
    const apiService = ReportsApiService.instance;
    return await apiService.checkReport(reportId);
  }

  async fetchUncheckedReports(): Promise<void> {
    const apiService = ReportsApiService.instance;
    const reports = await apiService.getUncheckedReports();

    if (reports === undefined) return;

    this.commit("setReports", reports);
  }

  async fetchReportById(reportId: number): Promise<void> {
    const apiService = ReportsApiService.instance;
    const report = await apiService.getReportById(reportId);

    if (report === undefined) return;

    this.commit("setReport", report);
  }

  async fetchUncheckedReportsCount(): Promise<void> {
    const apiService = ReportsApiService.instance;
    const count = await apiService.getUncheckedReportsCount();

    if (count === undefined) return;

    this.commit("setUncheckedCount", count);
  }

  async fetchReportsByCategory(categoryId: number): Promise<void> {
    const apiService = ReportsApiService.instance;
    const reports = await apiService.getReportsByCategory(categoryId);
    if (!reports) return;

    this.commit("setReports", reports);
  }

  async fetchAllReports(): Promise<void> {
    const apiService = ReportsApiService.instance;
    const reports = await apiService.getAllReports();

    if (!reports) return;

    this.commit("setReports", reports);
  }

  async deleteReport(reportId: number): Promise<boolean> {
    const apiService = ReportsApiService.instance;
    return await apiService.deleteReport(reportId);
  }

  async fetchReportData(reportId: number): Promise<void> {
    const apiService = ReportsApiService.instance;
    const workbookModel = await apiService.getParsedData(reportId);

    if (workbookModel === undefined) return;

    this.commit("setReportData", workbookModel);
  }
}

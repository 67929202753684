
import { defineComponent } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";
import Button from "primevue/button";

import ReportsTable from "@/components/PersonalCabinet/ReportsTable.vue";
import XlsxModelView from "@/components/XlsxModelView/XlsxModelView.vue";

export default defineComponent({
  name: "Report",
  data() {
    return {
      loadPreview: false,
    };
  },
  props: {
    reportId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...reportsModuleMapper.mapGetters(["report", "reportData"]),
  },
  methods: {
    ...reportsModuleMapper.mapActions([
      "resetReport",
      "fetchReportById",
      "fetchReportData",
    ]),
    async updateReport() {
      await this.fetchReportById(this.reportId);
      if (!this.report?.id) {
        this.$router.back();
        await this.$router.push("/cabinet");
      }
      await this.fetchReportData(this.reportId);
    },
  },
  beforeMount() {
    this.resetReport();
  },
  async mounted() {
    await this.updateReport();
  },
  components: {
    XlsxModelView,
    ReportsTable,
    Button,
  },
});

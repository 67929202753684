
import Button from "primevue/button";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "UserInfo",
  computed: {
    ...authorizationModuleMapper.mapGetters(["currentUser"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["logOut"]),
    handlePersonalCabinetButtonClick() {
      this.$router.push({ path: "/cabinet" });
    },
    async handleLogoutButtonClick() {
      await this.logOut();
      this.$router.go(0);
    },
  },
  components: {
    Button,
  },
});


import { defineComponent } from "vue";

import Dialog from "primevue/dialog";
import Button from "primevue/button";

export default defineComponent({
  name: "ConfirmationDialog",
  data() {
    return {
      showModel: false,
    };
  },
  emits: ["confirm", "decline"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleConfirmButtonClick() {
      this.$emit("confirm");
    },
    handleDeclineButtonClick() {
      this.$emit("decline");
    },
  },
  watch: {
    showModel(newValue) {
      if (!newValue) this.$emit("decline", newValue);
    },
    show(newValue) {
      this.showModel = newValue;
    },
  },
  mounted() {
    this.showModel = this.show;
  },
  components: { Dialog, Button },
});

<template>
  <Toast />
  <div class="personal-cabinet">
    <Menu v-if="loaded" />
    <div class="container" v-if="loaded">
      <div class="container-inner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Menu from "@/components/PersonalCabinet/Menu.vue";
import Toast from "primevue/toast";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "PersonalCabinet",
  async mounted() {
    if (!this.loaded) await this.fetchCurrentState();
  },
  computed: {
    ...authorizationModuleMapper.mapGetters(["loaded", "currentUser"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["fetchCurrentState"]),
  },
  components: {
    Menu,
    Toast,
  },
});
</script>

<style lang="scss" scoped>
.personal-cabinet {
  .container-inner {
    margin-top: 50px;
  }
}
</style>

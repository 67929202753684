import { Actions } from "vuex-smart-module";
import ReportCategoriesState from "@/store/modules/reportCategories/reportCategoriesState";
import ReportCategoriesGetters from "@/store/modules/reportCategories/reportCategoriesGetters";
import ReportCategoriesMutations from "@/store/modules/reportCategories/reportCategoriesMutations";
import ReportCategoriesApiService from "@/api/ReportCategoriesApiService";

export default class ReportCategoriesActions extends Actions<
  ReportCategoriesState,
  ReportCategoriesGetters,
  ReportCategoriesMutations,
  ReportCategoriesActions
> {
  async fetchCategories(): Promise<void> {
    const apiService = ReportCategoriesApiService.instance;
    const categories = await apiService.getCategories();

    if (!categories) return;

    this.commit("setReportCategories", categories);
    this.commit("setLoaded", true);
  }
}

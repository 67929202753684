import { Mutations } from "vuex-smart-module";
import ReportsState from "@/store/modules/reports/reportsState";
import ReportModel from "@/models/ReportModel";
import { WorkbookModel } from "@xlsx-model/models";

export default class ReportsMutations extends Mutations<ReportsState> {
  setReports(payload: ReportModel[] | undefined): void {
    this.state.reports = payload;
  }

  setUncheckedCount(payload: number): void {
    this.state.uncheckedReportsCount = payload;
  }

  setReport(payload: ReportModel | undefined): void {
    this.state.report = payload;
  }

  setReportData(payload: WorkbookModel): void {
    this.state.reportData = payload;
  }
}

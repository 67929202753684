<template>
  <tr :style="{ minHeight: height }" v-if="!row.hidden">
    <td :style="{ border: 'none' }">
      <OutlineControl
        @click="handleOutlineControlClick"
        :collapsed="row.collapsed || false"
        v-if="row.isMaster"
      />
    </td>
    <XlsxCell
      v-for="(cell, key) in cells"
      :key="key"
      :cell-model="cell[0]"
      :column-markup="cell[1]"
      :scaling="scaling"
    />
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CellModel, ColumnMarkup } from "@xlsx-model/models";
import XlsxCell from "@/components/XlsxModelView/XlsxCell.vue";
import { Dictionary } from "typescript-collections";
import ScalingModel from "@/models/Xlsx/ScalingModel";
import OutlineControl from "@/components/XlsxModelView/OutlineControl.vue";
import Row from "@/models/Xlsx/Row";

export default defineComponent({
  name: "XlsxRow",
  components: { OutlineControl, XlsxCell },
  props: {
    row: {
      type: Object as PropType<Row>,
      required: false,
    },
    columnsMarkup: {
      type: Object as PropType<Dictionary<number, ColumnMarkup>>,
      required: true,
    },
    cellsCount: {
      type: Number,
      required: false,
      default: 0,
    },
    scaling: {
      type: Object as PropType<ScalingModel>,
      required: false,
    },
  },
  emits: ["collapse", "expand"],
  computed: {
    cells() {
      const cells: [CellModel, ColumnMarkup | undefined][] = [];

      this.row?.model?.cells.forEach((key, value) => {
        let columnMarkup: ColumnMarkup | undefined;
        if (this.columnsMarkup.containsKey(key))
          columnMarkup = this.columnsMarkup.getValue(key);
        cells.push([value, columnMarkup]);
      });

      return cells;
    },
    height(): string | undefined {
      let height = this.row?.markup?.height;
      if (!height) return undefined;

      height = height * (this.scaling?.height || 1);
      return height.toString() + "px";
    },
  },
  methods: {
    handleOutlineControlClick(): void {
      if (!this.row) return;
      if (this.row.collapsed) this.$emit("expand", this.row);
      else this.$emit("collapse", this.row);
    },
  },
});
</script>

<style lang="scss" scoped></style>


import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportStatus",
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
});

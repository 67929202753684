import { Getters } from "vuex-smart-module";
import DynamicReportState from "@/store/modules/dynamicReport/dynamicReportState";
import { WorkbookModel } from "@xlsx-model/models";
import DynamicReportModel from "@/models/DynamicReportModel";

export default class DynamicReportGetters extends Getters<DynamicReportState> {
  get dynamicReportData(): WorkbookModel | undefined {
    return this.state.dynamicReportData;
  }

  get dynamicReport(): DynamicReportModel | undefined {
    return this.state.dynamicReport;
  }
}

<template>
  <div>
    <h1>Непроверенные отчёты:</h1>
    <ProgressSpinner v-if="isLoading" />
    <ReportsTable
      :reports="reports"
      v-else-if="reports !== undefined && reports.length > 0"
    />
    <p v-else>Список непроверенных отчетов пуст</p>
  </div>
</template>

<script lang="ts">
import ReportsTable from "@/components/PersonalCabinet/ReportsTable.vue";
import ProgressSpinner from "primevue/progressspinner";

import { defineComponent } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";

export default defineComponent({
  name: "Messages",
  data() {
    return {
      isLoading: true,
    };
  },
  props: {
    updateId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...reportsModuleMapper.mapGetters(["reports"]),
  },
  methods: {
    ...reportsModuleMapper.mapActions([
      "resetReports",
      "fetchUncheckedReports",
    ]),
    async updateTable() {
      this.isLoading = true;
      this.resetReports();
      await this.fetchUncheckedReports();
      this.isLoading = false;
    },
  },
  watch: {
    async updateId() {
      await this.updateTable();
    },
  },
  async mounted() {
    await this.updateTable();
  },
  components: {
    ReportsTable,
    ProgressSpinner,
  },
});
</script>

<style lang="scss" scoped>
h1,
p {
  margin: 50px 10px;
}
</style>

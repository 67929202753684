import ApiServiceBase from "@/api/ApiServiceBase";
import { apiBaseUrl } from "@/api/api.config";
import { WorkbookModel } from "@xlsx-model/models";
import { WorkbookModelDeserializer } from "@xlsx-model/serializer";
import DynamicReportModel from "@/models/DynamicReportModel";
import { NomenclatureModel } from "@/models/NomenclatureModel";

export interface DynamicReportCreationArguments {
  categoryId: number;
  dateFrom?: Date;
  dateTo?: Date;
  nomenclatureCode?: string;
}

function createParametersObject(args: DynamicReportCreationArguments): any {
  const dateFrom = args.dateFrom;
  const dateTo = args.dateTo;

  let fromTimestamp: undefined | number;
  let toTimestamp: undefined | number;

  if (dateFrom)
    fromTimestamp = Date.UTC(
      dateFrom?.getFullYear(),
      dateFrom?.getMonth(),
      dateFrom?.getDate()
    );

  if (dateTo)
    toTimestamp = Date.UTC(
      dateTo?.getFullYear(),
      dateTo?.getMonth(),
      dateTo?.getDate()
    );

  return {
    ...args,
    dateFrom: fromTimestamp,
    dateTo: toTimestamp,
  };
}

export default class DynamicReportsApiService extends ApiServiceBase {
  private static _instance: DynamicReportsApiService | undefined;

  public static get instance(): DynamicReportsApiService {
    if (!this._instance) this._instance = new DynamicReportsApiService();
    return this._instance;
  }

  constructor() {
    super({
      baseURL: apiBaseUrl + "/dynamicReports",
      withCredentials: true,
    });
  }

  async findOrCreateDynamicReport(
    args: DynamicReportCreationArguments
  ): Promise<DynamicReportModel> {
    const response = await this.sendRequest({
      method: "post",
      url: "/findOrCreate",
      params: createParametersObject(args),
    });
    return <DynamicReportModel>response.data;
  }

  async createDynamicReport(
    args: DynamicReportCreationArguments
  ): Promise<DynamicReportModel> {
    const response = await this.sendRequest({
      method: "post",
      url: "/create",
      params: createParametersObject(args),
    });
    return <DynamicReportModel>response.data;
  }

  async getDynamicReportParsedData(
    dynamicReport: DynamicReportModel
  ): Promise<WorkbookModel> {
    const deserializer = new WorkbookModelDeserializer();
    const response = await this.sendRequest({
      method: "get",
      url: "/getParsed",
      params: {
        id: dynamicReport.id,
      },
    });
    return deserializer.deserialize(response.data);
  }

  async getNomenclaturesList(): Promise<NomenclatureModel[]> {
    const response = await this.sendRequest({
      method: "get",
      url: "/nomenclatures",
    });
    return response.data;
  }
}

<template>
  <CreateUserDialog
    :show="showCreateUserDialog"
    @confirm="handleConfirmNewUser"
    @decline="showCreateUserDialog = false"
  />
  <Toolbar class="toolbar">
    <template #left>
      <Button
        label="Добавить"
        icon="pi pi-plus"
        class="p-button-success"
        @click="handleAddButtonClick"
      />
    </template>
  </Toolbar>
  <UsersTable v-if="users !== undefined" :users="users" />
</template>

<script lang="ts">
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";

import CreateUserDialog, {
  CreateUserDialogResult,
} from "@/components/PersonalCabinet/CreateUserDialog.vue";

import { defineComponent } from "vue";
import UsersTable from "@/components/PersonalCabinet/UsersTable.vue";

import { usersModuleMapper } from "@/store/modules/users";
import { authorizationModuleMapper } from "@/store/modules/authorization";
import UserModel from "@/models/UserModel";
import UsersApiService from "@/api/UsersApiService";
import useShowErrorToast from "@/useShowErrorToast";

export default defineComponent({
  name: "Users",
  data() {
    return {
      showCreateUserDialog: false,
      newUser: undefined as undefined | UserModel,
    };
  },
  components: { UsersTable, Toolbar, Button, CreateUserDialog },
  methods: {
    ...usersModuleMapper.mapActions(["fetchUsers"]),
    handleAddButtonClick() {
      this.showCreateUserDialog = true;
    },
    async handleConfirmNewUser(userData: CreateUserDialogResult) {
      this.showCreateUserDialog = false;
      const apiService = UsersApiService.instance;
      const result = await apiService.registerUser(
        userData.login,
        userData.password,
        userData.firstName,
        userData.secondName
      );
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось создать пользователя",
          "Ошибка сервера. Не удалось создать пользователя. Обратитесь к системному администраторую."
        );
    },
  },
  setup() {
    const showErrorToast = useShowErrorToast();
    return { showErrorToast };
  },
  computed: {
    ...usersModuleMapper.mapGetters(["users"]),
    ...authorizationModuleMapper.mapGetters(["currentUser"]),
  },
  async mounted() {
    if ((this.currentUser?.role?.privilegeLevel || 1) < 2)
      await this.$router.push("/cabinet/");
    await this.fetchUsers();
  },
});
</script>

<style lang="scss" scoped>
.toolbar {
  margin: 30px 0;
}
</style>

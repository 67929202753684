import { Actions } from "vuex-smart-module";
import UsersState from "@/store/modules/users/usersState";
import UsersGetters from "@/store/modules/users/usersGetters";
import UsersMutations from "@/store/modules/users/usersMutations";
import UsersApiService from "@/api/UsersApiService";

export default class UsersActions extends Actions<
  UsersState,
  UsersGetters,
  UsersMutations,
  UsersActions
> {
  async fetchUsers(): Promise<void> {
    const apiService = UsersApiService.instance;
    const users = await apiService.getAllUsers();

    if (!users) return;

    this.commit("setUsers", users);
  }
}


import { defineComponent, PropType } from "vue";
import { reportsModuleMapper } from "@/store/modules/reports";

import ReportModel from "@/models/ReportModel";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import ReportStatus from "@/components/PersonalCabinet/ReportStatus.vue";

import moment from "moment";
import { apiBaseUrl } from "@/api/api.config";
import ConfirmationDialog from "@/components/PersonalCabinet/ConfirmationDialog.vue";
import useShowErrorToast from "@/useShowErrorToast";

export default defineComponent({
  name: "ReportsTable",
  data() {
    return {
      deleteConfirmationDialog: false,
      checkConfirmationDialog: false,
      confirmationDialogReportId: 0,
      downloadReportId: 0,
      expandedRowGroups: null,
    };
  },
  props: {
    reports: {
      type: Object as PropType<ReportModel[]>,
      required: true,
    },
    showGotoButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    groupByDate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    downloadLink(): string {
      return (
        apiBaseUrl + "/reports/download?id=" + this.downloadReportId.toString()
      );
    },
  },
  methods: {
    ...reportsModuleMapper.mapActions(["checkReport", "deleteReport"]),
    createDateString(date: Date): string {
      return moment(date).format("DD.MM.YYYY");
    },
    handleReportButtonClick(id: number) {
      this.$router.push({ path: "/cabinet/report", query: { reportId: id } });
    },
    showDeleteDialog(reportId: number) {
      this.deleteConfirmationDialog = true;
      this.confirmationDialogReportId = reportId;
    },
    showCheckDialog(reportId: number) {
      this.checkConfirmationDialog = true;
      this.confirmationDialogReportId = reportId;
    },
    handleCheckButtonClick(reportId: number) {
      this.showCheckDialog(reportId);
    },
    handleDeleteButtonClick(reportId: number) {
      this.showDeleteDialog(reportId);
    },
    async handleDeleteConfirmation() {
      this.deleteConfirmationDialog = false;
      const result = await this.deleteReport(this.confirmationDialogReportId);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось удалить отчёт",
          "Произошла ошибка сервера. Не удалось удалить отчёт. Обратитесь к системному администратору"
        );
    },
    async handleCheckConfirmation() {
      this.checkConfirmationDialog = false;
      const result = await this.checkReport(this.confirmationDialogReportId);
      if (result) this.$router.go(0);
      else
        this.showErrorToast(
          "Не удалось проверить отчёт",
          "Произошла ошибка сервера. Не удалось проверить отчёт. Обратитесь к системному администратору"
        );
    },
    async handleDownloadButtonClick(reportId: number) {
      this.downloadFile(reportId);
    },
    updateDownloadButton() {
      (<HTMLAnchorElement>this.$refs.downloadButton).href = this.downloadLink;
    },
    downloadFile(reportId: number) {
      this.downloadReportId = reportId;
      this.updateDownloadButton();
      (<HTMLAnchorElement>this.$refs.downloadButton).click();
    },
  },
  setup() {
    const showErrorToast = useShowErrorToast();
    return { showErrorToast };
  },
  components: {
    ConfirmationDialog,
    DataTable,
    Column,
    Button,
    ReportStatus,
  },
});


import Menu from "@/components/PersonalCabinet/Menu.vue";
import Toast from "primevue/toast";

import { defineComponent } from "vue";
import { authorizationModuleMapper } from "@/store/modules/authorization";

export default defineComponent({
  name: "PersonalCabinet",
  async mounted() {
    if (!this.loaded) await this.fetchCurrentState();
  },
  computed: {
    ...authorizationModuleMapper.mapGetters(["loaded", "currentUser"]),
  },
  methods: {
    ...authorizationModuleMapper.mapActions(["fetchCurrentState"]),
  },
  components: {
    Menu,
    Toast,
  },
});

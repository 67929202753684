export interface IRoleModel {
  id: number;
  name: string;
  privilegeLevel: number;
}

export default class RoleModel implements IRoleModel {
  public static from(value: IRoleModel): RoleModel {
    return new RoleModel(value.id, value.name, value.privilegeLevel);
  }

  constructor(id: number, name: string, privilegeLevel: number) {
    this.id = id;
    this.name = name;
    this.privilegeLevel = privilegeLevel;
  }

  id: number;
  name: string;
  privilegeLevel: number;
}

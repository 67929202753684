import { createMapper, Module } from "vuex-smart-module";
import DynamicReportCategoriesState from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesState";
import DynamicReportCategoriesActions from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesActions";
import DynamicReportCategoriesMutations from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesMutations";
import DynamicReportCategoriesGetters from "@/store/modules/dynamicReportCategories/dynamicReportCategoriesGetters";

export const dynamicReportCategoriesModule = new Module({
  state: DynamicReportCategoriesState,
  getters: DynamicReportCategoriesGetters,
  mutations: DynamicReportCategoriesMutations,
  actions: DynamicReportCategoriesActions,
});

export const dynamicReportCategoriesModuleMapper = createMapper(
  dynamicReportCategoriesModule
);

<template>
  <table
    v-if="worksheet !== undefined && rowsModel !== undefined"
    :key="tableKey"
  >
    <XlsxRow
      v-for="(row, rowId) in rowsModel"
      :key="rowId"
      :row="row"
      :cells-count="columnsCount"
      :columns-markup="worksheet.tableMarkup.columns"
      :scaling="scaling"
      @expand="handleExpand"
      @collapse="handleCollapse"
    />
  </table>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import XlsxRow from "@/components/XlsxModelView/XlsxRow.vue";
import ScalingModel from "@/models/Xlsx/ScalingModel";

import Row from "@/models/Xlsx/Row";
import { WorksheetModel } from "@xlsx-model/models";
import { prepareXlsxRows } from "@/helpers/prepareXlsxRows";

export default defineComponent({
  name: "XlsxWorksheet",
  components: { XlsxRow },
  data() {
    return {
      rowsModel: [] as Row[],
      tableKey: 0,
    };
  },
  props: {
    worksheet: {
      type: Object as PropType<WorksheetModel>,
      required: true,
    },
    scaling: {
      type: Object as PropType<ScalingModel>,
      required: false,
    },
  },
  computed: {
    rowCount(): number {
      return this.worksheet?.tableMarkup.rowsCount || 0;
    },
    columnsCount(): number {
      return this.worksheet?.tableMarkup.columnsCount || 0;
    },
  },
  methods: {
    showDependent(row: Row): Row[] {
      return row.dependentRows.map<Row>((value: Row) => {
        value.hidden = false;
        if (value.isMaster && !value.collapsed)
          value.dependentRows = this.showDependent(value);
        return value;
      });
    },
    hideDependent(row: Row): Row[] {
      return row.dependentRows.map<Row>((value: Row) => {
        value.hidden = true;
        if (value.isMaster) value.dependentRows = this.hideDependent(value);
        return value;
      });
    },
    handleCollapse(row: Row) {
      const rowModel = this.rowsModel[row.id];
      if (!rowModel) return;

      rowModel.collapsed = true;
      this.hideDependent(<Row>rowModel);
    },
    handleExpand(row: Row) {
      const rowModel = this.rowsModel[row.id];
      if (!rowModel) return;

      rowModel.collapsed = false;
      this.showDependent(<Row>rowModel);
    },
  },
  async mounted() {
    this.rowsModel = prepareXlsxRows(this.worksheet);
  },
});
</script>

<style lang="scss" scoped>
table {
  width: fit-content;
}
</style>

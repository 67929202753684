<template>
  <Dialog
    v-model:visible="showModel"
    :style="{ width: '450px' }"
    header="Добавление пользователя"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="login">Логин</label>
      <InputText
        id="login"
        v-model.trim="login"
        required="true"
        autofocus
        :class="{ 'p-invalid': !isLoginValid }"
      />
      <small class="p-error" v-if="!isLoginValid"
        >Логин имеет недопустимые символы</small
      >
    </div>
    <div class="p-field">
      <label for="firstName">Имя</label>
      <InputText
        id="firstName"
        v-model.trim="firstName"
        required="true"
        :class="{ 'p-invalid': !isFirstNameValid }"
      />
      <small class="p-error" v-if="!isFirstNameValid"
        >Имя имеет недопустимые символы</small
      >
    </div>
    <div class="p-field">
      <label for="secondName">Фамилия</label>
      <InputText
        id="secondName"
        v-model.trim="secondName"
        required="true"
        :class="{ 'p-invalid': !isSecondNameValid }"
      />
      <small class="p-error" v-if="!isSecondNameValid"
        >Фамилия имеет недопустимые символы</small
      >
    </div>
    <div class="p-field">
      <label for="password">Пароль</label>
      <Password
        id="password"
        v-model.trim="password"
        required="true"
        :class="{ 'p-invalid': !isPasswordValid }"
      />
      <small class="p-error" v-if="!isPasswordValid"
        >Фамилия имеет недопустимые символы</small
      >
    </div>
    <template #footer>
      <Button
        label="Сохранить"
        icon="pi pi-check"
        class="p-button-text"
        @click="save"
      />
      <Button
        label="Отмена"
        icon="pi pi-times"
        class="p-button-text"
        @click="cancel"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";

export interface CreateUserDialogResult {
  login: string;
  password: string;
  firstName: string;
  secondName: string;
}

export default defineComponent({
  name: "CreateUserDialog",
  data() {
    return {
      firstName: "",
      secondName: "",
      login: "",
      password: "",

      isLoginValid: true,
      isFirstNameValid: true,
      isSecondNameValid: true,
      isPasswordValid: true,

      showModel: false,
    };
  },
  components: { Dialog, InputText, Button, Password },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    validateLogin(login: string): boolean {
      const matches = login.match(/[A-z0-9-_]+/);
      return matches !== null && matches[0] === login;
    },
    validateFirstName(firstName: string): boolean {
      const matches = firstName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === firstName;
    },
    validateSecondName(secondName: string): boolean {
      const matches = secondName.match(/[A-zА-яё]+/);
      return matches !== null && matches[0] === secondName;
    },
    validatePassword(password: string): boolean {
      const matches = password.match(/[A-z0-9-_]+/);
      return matches !== null && matches[0] === password;
    },
    validate(): boolean {
      this.isLoginValid = this.validateLogin(this.login);
      this.isFirstNameValid = this.validateFirstName(this.firstName);
      this.isSecondNameValid = this.validateSecondName(this.secondName);
      this.isPasswordValid = this.validatePassword(this.password);

      return (
        this.isLoginValid && this.isFirstNameValid && this.isSecondNameValid
      );
    },
    save() {
      if (this.validate()) {
        this.$emit("confirm", {
          login: this.login,
          password: this.password,
          firstName: this.firstName,
          secondName: this.secondName,
        } as CreateUserDialogResult);
      }
    },
    cancel() {
      this.hide();
    },
    hide() {
      this.login = "";
      this.password = "";
      this.firstName = "";
      this.secondName = "";
      this.$emit("decline");
    },
  },
  watch: {
    showModel(newValue) {
      if (!newValue) this.hide();
    },
    show(newValue) {
      this.showModel = newValue;
    },
  },
  mounted() {
    this.showModel = this.show;
  },
  emits: ["confirm", "decline"],
});
</script>

<style lang="scss" scoped>
.p-field {
  margin: 10px 0;
  label {
    margin-bottom: 10px;
  }
}
</style>
